import React from "react"

const AdditionalDist = ({ additionalDist, additionalDistList }) => {
  function getNameInitials(name) {
    let words = name.split(" ")
    let initials = ""
    for (let i = 0; i < words.length; i++) {
      initials += words[i].charAt(0)
    }
    initials = initials.toUpperCase()
    return initials
  }
  return (
    <div className="container  mt-2">
      <div className="row pb-2 pt-4 border1 rounded shadow">
        <div className="col-12">
          <h6 className="module-title rounded title-label-text-color border m-0 px-2 py-1 d-flex justify-content-between align-items-center">
            <span>
              <i className="bi bi-mic-fill" data-html2canvas-ignore="true"></i>
              Additional Distribution
            </span>
          </h6>
        </div>

        <div className="col-12 mt-2">
          <table className="table table-bordered">
            <thead>
              <tr className="module-field field-label-text-color">
                <th scope="col">Sr</th>
                <th scope="col">Name</th>
                <th scope="col">Initial</th>
                <th scope="col">Organization</th>
              </tr>
            </thead>
            <tbody>
              {additionalDistList.map((addDist, index) => (
                <tr key={addDist.id}>
                  <td>{index + 1}</td>
                  <td>{addDist.name}</td>
                  <td>{getNameInitials(addDist.name)}</td>
                  <td>{addDist.organization}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AdditionalDist
