import React from "react";
import { Col } from "antd";

const AttendeesModal = () => {
  return (
    <Col span={6} style={{ position: "sticky", top: 0 ,right:'20px'}}>
      <Col className="content">
        <div
          className="row"
          style={{
            maxHeight: "630px",
            overflowY: "auto",
          }}
        >
          <div className="col-lg-12">
            <div className="form-group">
              <label className=" text-info">
                <b> How to shift attendees record to apologies ?</b>
              </label>
              <br />
              <br />
              <label>
                - If Attendees section is not focused then say
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-mic"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                  <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                </svg>
                <b> "attendees"</b>. after that, by calling serial number of
                desired record you can shift it to apologies
              </label>
              <br />
              <br />
            </div>
          </div>
          <br />
          <hr />
          <div className="col-lg-12">
            <div className="form-group">
              <label className=" text-info">
                <b> How to shift apologies record to attendees ?</b>
              </label>
              <br />
              <br />
              <label>
                - If apologies section is not focused then say
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-mic"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                  <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                </svg>
                <b> "apologies"</b>. after that, by calling serial number of
                desired record you can shift it to attendees
              </label>
            </div>
          </div>
        </div>
      </Col>
    </Col>
  );
};

export default AttendeesModal;
