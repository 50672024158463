import "./Attendees.css"
import React from "react"
import PropTypes from "prop-types"

function Attendees({
  fields,
  apologiesList,
  selectedProject,
  helpModalAttendees,
  onHelpModalAttendees,
  onHelpHideModalAttendees,
  helpModalApologies,
  onHelpModalApologies,
  onHelpHideModalApologies,
  closeModal,
  onHelpHideModalNextProject,
  onHelpHideModalMinutes,
}) {
  function getNameInitials(name) {
    let words = name.split(" ")
    let initials = ""
    for (let i = 0; i < words.length; i++) {
      initials += words[i].charAt(0)
    }
    initials = initials.toUpperCase()
    return initials
  }
  return (
    <div className="container  mt-2">
      <div className="row pb-3 pt-4 border1 rounded shadow">
        <div className="col-6">
          <h6 className="module-title rounded title-label-text-color border m-0 px-2 py-1 d-flex justify-content-between align-items-center">
            <span>
              <i className="bi bi-mic-fill" data-html2canvas-ignore="true"></i>{" "}
              Attendees
            </span>
            {helpModalAttendees ? (
              <span onClick={onHelpHideModalAttendees}>
                <i className="bi bi-x"></i>
              </span>
            ) : (
              <span
                onClick={() => {
                  onHelpModalAttendees()
                  closeModal()
                  onHelpHideModalNextProject()
                  onHelpHideModalMinutes()
                  onHelpHideModalApologies()
                }}
                data-html2canvas-ignore="true"
              >
                Help
              </span>
            )}
          </h6>
        </div>
        <div className="col-6">
          <h6 className="module-title rounded title-label-text-color border m-0 px-2 py-1 d-flex justify-content-between align-items-center">
            <span>
              <i className="bi bi-mic-fill" data-html2canvas-ignore="true"></i>{" "}
              Apologies
            </span>
            {helpModalApologies ? (
              <span onClick={onHelpHideModalApologies}>
                <i className="bi bi-x"></i>
              </span>
            ) : (
              <span
                onClick={() => {
                  onHelpModalApologies()
                  closeModal()
                  onHelpHideModalNextProject()
                  onHelpHideModalMinutes()
                  onHelpHideModalAttendees()
                }}
                data-html2canvas-ignore="true"
              >
                Help
              </span>
            )}
          </h6>
        </div>
        <div className="col-6 mt-2">
          <table className="table table-bordered">
            <thead>
              <tr className="module-field field-label-text-color">
                <th scope="col">Sr</th>
                <th scope="col">Name</th>
                <th scope="col">Initial</th>
                <th scope="col">Organization</th>
              </tr>
            </thead>
            <tbody className={selectedProject === 3 ? "projects-wrapper" : ""}>
              {fields.map((each, index) => {
                return (
                  <tr key={each.id}>
                    <td>{index + 1}</td>
                    <td data-id={index}>{each.name}</td>
                    <td>{getNameInitials(each.name)}</td>
                    <td>{each.organization}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="col-6 mt-2">
          <table className="table table-bordered">
            <thead>
              <tr className="module-field field-label-text-color">
                <th scope="col">Sr</th>
                <th scope="col">Name</th>
                <th scope="col">Initial</th>
                <th scope="col">Organization</th>
              </tr>
            </thead>
            <tbody className={selectedProject === 5 ? "projects-wrapper" : ""}>
              {apologiesList.map((each, index) => {
                return (
                  <tr key={each.id}>
                    <td>{index + 1}</td>
                    <td data-id={index}>{each.name}</td>
                    <td>{getNameInitials(each.name)}</td>
                    <td>{each.organization}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
Attendees.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  formData: PropTypes.shape({
    srNo: PropTypes.string,
    name: PropTypes.string,
    initial: PropTypes.string,
    organization: PropTypes.string,
  }),
  refs: PropTypes.arrayOf(PropTypes.object),
  _handleChange: PropTypes.func,
  _setActiveNextProjectInput: PropTypes.func,
}

Attendees.defaultProps = {
  fields: [],
  formData: {},
  refs: [],
  isAttendess: 0,
  isApologies: 0,
}

export default Attendees
