import { Col } from "antd";
import React from "react";

const ProjectModal = () => {
  return (
    <Col span={6} style={{ position: "sticky", top: 0 ,right:'20px'}}>
      <Col className="content">
        <div
          className="row"
          style={{
            maxHeight: "630px",
            overflowY: "auto",
          }}
        >
          <div className="col-lg-12">
            <div className="form-group">
              <label className=" text-info">
                <b> How to Start ?</b>
              </label>
              <br />
              <label>
                - Click on
                <span className="bg-success text-white">
                  &nbsp; start &nbsp;
                </span>
                button to begin speech recognition
              </label>
              <br />
              <br />
            </div>
          </div>
          <br />
          <hr />
          <div className="col-lg-12">
            <div className="form-group">
              <label className=" text-info">
                <b> How to stop speech recognition ?</b>
              </label>
              <br />
              <label>
                - say
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-mic"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                  <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                </svg>
                <b> "stop recording"</b> to stop speech <b> OR</b> click on
                <span className="bg-danger text-white">&nbsp; Stop &nbsp;</span>
                to stop speech recognition
              </label>
            </div>
          </div>

          <div className="col-lg-12">
            <hr />
            <div className="form-group">
              <label className=" text-info">
                <b> How it works ?</b>
              </label>
              <br />
              <label>
                - To trigger event start with saying heading name <br /> Ex.
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-mic"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                  <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                </svg>
                <b> "Add Project / Go to date / Go to minutes" </b>
                <br />
              </label>
              <br />
              <hr />
              <label className=" text-info">
                <b> How to easy navigate without adding word ?</b>
              </label>
              <br />
              <label>
                - This work in
                <span className="text text-success">
                  "Project Reference" , "Date", "Meeting" and "Address"
                </span>
                section <br />- To move previous text box say
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-mic"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                  <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                </svg>
                <b> "Go back / Previous"</b> <br />- To move next text box say
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-mic"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                  <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                </svg>
                <b> "Next" </b>
              </label>
            </div>
          </div>
          <div className="col-lg-12">
            <hr />
            <div className="form-group">
              <label className=" text-info">
                <b> How to pause speech recognition ?</b>
              </label>
              <br />
              <label>
                - say
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-mic"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                  <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                </svg>
                <b> "pause recording"</b> to pause speech recognition
              </label>
              <br />
              <hr />
            </div>
            <div className="form-group">
              <label className=" text-info">
                <b> How to save document ?</b>
              </label>
              <br />
              <label>
                - say
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-mic"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                  <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                </svg>
                <b>
                  <span className="bg-success text-white">
                    &nbsp; SUBMIT &nbsp;
                  </span>
                </b>{" "}
                to save your document and distribute.
              </label>
              <br />
            </div>
          </div>
        </div>
      </Col>
    </Col>
  );
};

export default ProjectModal;
