import "./Minutes.css"
import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import $ from "jquery"

function Minutes({
  fields,
  attendees,
  formData,
  minuteRefs,
  ownerRefs,
  dateRefs,
  statusRefs,
  date_status,
  owner_status,
  meeting_status,
  submitted,
  labelRefs,
  labelRef,
  labelButton,
  activeInput,
  _lineCount,
  _setActiveMinutesProjectInput,
  _handleChange,
  _minuteLineCount,
  _handleHeightMinute,
  rowNum,
  helpModalMinutes,
  onHelpModalMinutes,
  onHelpHideModalMinutes,
  tableNumber,
  onCheckboxChange,
  closeModal,
  onHelpHideModalNextProject,
  onHelpHideModalAttendees,
  onHelpHideModalApologies,
}) {
  const [height, setHeight] = useState("inherit")
  const [setScrollHeight] = useState()

  let i = 0
  let j = 0
  let k = 0

  useEffect(() => {
    if (activeInput === `minute${rowNum}`) {
      $(`textarea[name='${activeInput}']`)
        .each(function() {
          this.setAttribute("style", "height:" + this.scrollHeight + "px;")
        })
        .on(
          "focus",
          function() {
            this.style.height = "inherit"
            this.style.height = this.scrollHeight + "px"
            setHeight($(`textarea[name='${activeInput}']`).height())
          },
          "input",
          function() {
            this.style.height = Math.max(height, this.scrollHeight) + "px"
            setScrollHeight(this.scrollHeight)
          }
        )
      $(`textarea[name='${activeInput}']`).trigger("focus")
      $(`textarea[name='${activeInput}']`).trigger("input")
      setHeight($(`textarea[name='${activeInput}']`).height())
      $(`textarea[name='owner${rowNum}']`).height(height)
      $(`textarea[name='date${rowNum}']`).height(height)
      $(`textarea[name='status${rowNum}']`).height(height)
    }
  })

  useEffect(() => {
    let lht = parseInt(
      $(`textarea[name='owner${rowNum}']`).css("lineHeight"),
      10
    )
    let lines = Math.round(height / lht)
    _handleHeightMinute($(`textarea[name='${activeInput}']`).height())
    _lineCount(lines)
  }, [height])

  const calculateMinuteWidth = () => {
    const numColumnsShowing = [
      date_status == 1,
      owner_status == 1,
      meeting_status == 1,
    ].filter(Boolean).length
    if (numColumnsShowing === 0) {
      return "90%"
    } else if (numColumnsShowing === 1) {
      return "80%"
    } else if (numColumnsShowing === 2) {
      return "70%"
    } else {
      return "60%"
    }
  }
  let counter = 1
  return (
    <div className="container  mt-1">
      <div className="row pb-3 pt-4 border1 rounded shadow">
        <div className="col-12">
          <h6 className="module-title rounded title-label-text-color border m-0 px-2 py-1 d-flex justify-content-between align-items-center">
            <span>
              <i className="bi bi-mic-fill" data-html2canvas-ignore="true"></i>{" "}
              Minutes
            </span>
            {helpModalMinutes ? (
              <span onClick={onHelpHideModalMinutes}>
                <i className="bi bi-x"></i>
              </span>
            ) : (
              <span
                onClick={() => {
                  onHelpModalMinutes()
                  closeModal()
                  onHelpHideModalNextProject()
                  onHelpHideModalAttendees()
                  onHelpHideModalApologies()
                }}
                data-html2canvas-ignore="true"
              >
                Help
              </span>
            )}
          </h6>
        </div>
        <div className="col-12 mt-2">
          <table className="table table-bordered">
            <thead>
              <tr className="module-field field-label-text-color">
                <th scope="col">Sr</th>
                <th scope="col">Ref</th>
                <th scope="col" style={{ width: calculateMinuteWidth() }}>
                  Minute
                </th>

                {owner_status == 1 && (
                  <th scope="col" style={{ width: "10%" }}>
                    Owner
                  </th>
                )}
                {date_status == 1 && (
                  <th scope="col" style={{ width: "10%" }}>
                    Date
                  </th>
                )}
                {meeting_status == 1 && (
                  <th scope="col" style={{ width: "10%" }}>
                    Status
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {fields.map((each, index) => {
                return (
                  <Fragment key={each.title}>
                    {each.minutes.some(
                      (minute) => formData[minute.minute] !== ""
                    ) ? (
                      <tr className="bg-gray text-dark" ref={labelRef[k++]}>
                        <th scope="col">{submitted ? counter++ : each.SrNo}</th>
                        <th scope="col"></th>
                        <th scope="col" colSpan="4">
                          <input
                            type="text"
                            className="bg-gray text-dark"
                            value={formData[each.title]}
                            ref={labelRefs[j++]}
                            size="50"
                            disabled={labelButton}
                            name={`title${each.SrNo}`}
                            style={{ border: "none" }}
                            onChange={_handleChange}
                            onFocus={() =>
                              _setActiveMinutesProjectInput(each.title)
                            }
                          />
                        </th>
                      </tr>
                    ) : (
                      <tr
                        className="bg-gray text-dark"
                        ref={labelRef[k++]}
                        data-html2canvas-ignore="true"
                      >
                        <th scope="col">{each.SrNo}</th>
                        <th scope="col"></th>
                        <th scope="col" colSpan="4">
                          <input
                            type="text"
                            className="bg-gray text-dark"
                            value={formData[each.title]}
                            ref={labelRefs[j++]}
                            size="50"
                            disabled={labelButton}
                            name={`title${each.SrNo}`}
                            style={{ border: "none" }}
                            onChange={_handleChange}
                            onFocus={() =>
                              _setActiveMinutesProjectInput(each.title)
                            }
                          />
                        </th>
                      </tr>
                    )}

                    {each.minutes.map((subEach, subIndex) => {
                      const refValue = submitted
                        ? (() => {
                            const filteredData = each.minutes.filter(
                              (item) => formData[item.minute] !== ""
                            )
                            const newRefIndex = filteredData.findIndex(
                              (item) => item.ref === subEach.ref
                            )
                            return `${counter - 1}.${newRefIndex + 1}.`
                          })()
                        : subEach.ref

                      return formData[subEach.minute] !== "" ? (
                        <tr key={refValue}>
                          <td></td>
                          <td>{refValue}</td>
                          <td>
                            <textarea
                              ref={
                                owner_status != 1 &&
                                date_status != 1 &&
                                meeting_status != 1
                                  ? minuteRefs[i++]
                                  : minuteRefs[i]
                              }
                              name={"minute" + refValue}
                              style={{
                                height: formData[subEach.minuteLineHeight] + 10,
                              }}
                              onFocus={() =>
                                _setActiveMinutesProjectInput(subEach.minute)
                              }
                              onChange={_handleChange}
                              onBlur={() =>
                                _minuteLineCount(
                                  subEach.minuteLineCount,
                                  subEach.minuteLineHeight
                                )
                              }
                              placeholder={
                                refValue === "1.1"
                                  ? "This is already exist comment"
                                  : ""
                              }
                              value={formData[subEach.minute]}
                              className="form-control new-line"
                            ></textarea>
                          </td>
                          {owner_status == 1 && (
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <input
                                type="checkbox"
                                checked={tableNumber.includes(subEach.owner)}
                                onChange={() => onCheckboxChange(subEach.owner)}
                                style={{ marginRight: '5px' }}
                                data-html2canvas-ignore="true"
                                /> */}
                                <textarea
                                  ref={
                                    date_status != 1 && meeting_status != 1
                                      ? ownerRefs[i++]
                                      : ownerRefs[i]
                                  }
                                  name={"owner" + refValue}
                                  id={"ownermyid"}
                                  style={{
                                    height:
                                      formData[subEach.minuteLineHeight] + 10,
                                    //  backgroundColor: tableNumber.includes(subEach.owner) ? "grey" : "white",
                                    backgroundColor: "white",
                                    borderCollapse: "collapse",
                                  }}
                                  onChange={_handleChange}
                                  onFocus={() =>
                                    _setActiveMinutesProjectInput(subEach.owner)
                                  }
                                  onBlur={() => {}}
                                  className="form-control new-line"
                                  value={formData[subEach.owner]}
                                ></textarea>
                              </div>
                            </td>
                          )}
                          {date_status == 1 && (
                            <td>
                              <textarea
                                ref={
                                  meeting_status != 1
                                    ? dateRefs[i++]
                                    : dateRefs[i]
                                }
                                name={"date" + refValue}
                                onChange={_handleChange}
                                style={{
                                  height:
                                    formData[subEach.minuteLineHeight] + 10,
                                }}
                                onFocus={() =>
                                  _setActiveMinutesProjectInput(subEach.date)
                                }
                                className="form-control new-line"
                                value={formData[subEach.date]}
                              ></textarea>
                            </td>
                          )}
                          {meeting_status == 1 && (
                            <td>
                              <textarea
                                ref={statusRefs[i++]}
                                name={"status" + refValue}
                                onChange={_handleChange}
                                style={{
                                  height:
                                    formData[subEach.minuteLineHeight] + 10,
                                }}
                                onFocus={() =>
                                  _setActiveMinutesProjectInput(subEach.status)
                                }
                                className="form-control new-line"
                                value={formData[subEach.status]}
                              ></textarea>
                            </td>
                          )}
                        </tr>
                      ) : (
                        <tr key={refValue} data-html2canvas-ignore="true">
                          <td></td>
                          <td>{refValue}</td>
                          <td>
                            <textarea
                              ref={
                                owner_status != 1 &&
                                date_status != 1 &&
                                meeting_status != 1
                                  ? minuteRefs[i++]
                                  : minuteRefs[i]
                              }
                              name={"minute" + refValue}
                              style={{
                                height: formData[subEach.minuteLineHeight] + 10,
                              }}
                              onFocus={() =>
                                _setActiveMinutesProjectInput(subEach.minute)
                              }
                              onChange={_handleChange}
                              onBlur={() =>
                                _minuteLineCount(
                                  subEach.minuteLineCount,
                                  subEach.minuteLineHeight
                                )
                              }
                              placeholder={
                                refValue === "1.1"
                                  ? "This is already exist comment"
                                  : ""
                              }
                              value={formData[subEach.minute]}
                              className="form-control new-line"
                            ></textarea>
                          </td>
                          {owner_status == 1 && (
                            <td>
                              <textarea
                                ref={
                                  date_status != 1 && meeting_status != 1
                                    ? ownerRefs[i++]
                                    : ownerRefs[i]
                                }
                                // style={{ height: height }}
                                name={"owner" + refValue}
                                style={{
                                  height:
                                    formData[subEach.minuteLineHeight] + 10,
                                }}
                                onChange={_handleChange}
                                onFocus={() =>
                                  _setActiveMinutesProjectInput(subEach.owner)
                                }
                                className="form-control new-line"
                                value={formData[subEach.owner]}
                              ></textarea>
                            </td>
                          )}
                          {date_status == 1 && (
                            <td>
                              <textarea
                                ref={
                                  meeting_status != 1
                                    ? dateRefs[i++]
                                    : dateRefs[i]
                                }
                                // style={{ height: height }}
                                name={"date" + refValue}
                                onChange={_handleChange}
                                style={{
                                  height:
                                    formData[subEach.minuteLineHeight] + 10,
                                }}
                                onFocus={() =>
                                  _setActiveMinutesProjectInput(subEach.date)
                                }
                                className="form-control new-line"
                                value={formData[subEach.date]}
                              ></textarea>
                            </td>
                          )}
                          {meeting_status == 1 && (
                            <td>
                              <textarea
                                ref={statusRefs[i++]}
                                name={"status" + refValue}
                                onChange={_handleChange}
                                style={{
                                  height:
                                    formData[subEach.minuteLineHeight] + 10,
                                }}
                                onFocus={() =>
                                  _setActiveMinutesProjectInput(subEach.status)
                                }
                                className="form-control new-line"
                                value={formData[subEach.status]}
                              ></textarea>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
Minutes.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  refs: PropTypes.arrayOf(PropTypes.object),
  formData: PropTypes.shape({
    minute: PropTypes.string,
    owner: PropTypes.string,
    date: PropTypes.string,
    status: PropTypes.string,
  }),
  _handleChange: PropTypes.func,
  _setActiveMinutesProjectInput: PropTypes.func,
}

Minutes.defaultProps = {
  fields: [],
  formData: [],
  refs: [],
  _setActiveMinutesProjectInput: () => {},
}

export default Minutes
