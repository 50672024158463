import "./Project.css";
import React from "react";
import PropTypes from "prop-types";

function NextProject({
  fields,
  formData,
  refs,
  _handleChange,
  _setActiveNextProjectInput,
  _setActiveInput,
  helpModalNextProject,
  onHelpModalNextProject,
  onHelpHideModalNextProject,
  closeModal,
  onHelpHideModalMinutes,
  onHelpHideModalAttendees,
  onHelpHideModalApologies
}) {
  return (
    <div className="container  mt-2">
      <div className="row pb-4 pt-4 border1 rounded shadow">
        <div className="col-12">
          <h6 className="module-title rounded title-label-text-color border m-0 px-2 py-1 d-flex justify-content-between align-items-center">
            <span>
              <i className="bi bi-mic-fill" data-html2canvas-ignore="true"></i>Next Meeting
            </span>
            {helpModalNextProject ? 
                  <span onClick={onHelpHideModalNextProject}>
                  <i className="bi bi-x" ></i>
                 </span>
                    :  
                    <span onClick={()=>{
                      onHelpModalNextProject()
                      closeModal()
                      onHelpHideModalMinutes()
                      onHelpHideModalAttendees()         
                      onHelpHideModalApologies()         
                      } } data-html2canvas-ignore="true">
                    Help
                  </span>}
          </h6>
        </div>
        {fields.map((each, index) => {
          return (
            <div className="col-4 mt-3" key={each.name}>
              <h6 className="module-field rounded field-label-text-color m-0 px-2 py-1">
                <i className="bi bi-mic-fill" data-html2canvas-ignore="true"></i>
                {each.label}
              </h6>
              <input
                placeholder={each.placeholder}
                name={each.name}
                ref={refs[index]}
                onChange={_handleChange}
                onFocus={() => _setActiveNextProjectInput(each.name)}
                onBlur={() => _setActiveInput(null)}
                value={
                  // each.type === "date" && formData[each.name] !== ""
                  //   ? formatDate(each)
                  //   :
                  formData[each.name]
                }
                autoComplete="off"
                className="form-control mt-2"
              ></input>
              {/* {msg && <span>{msg}</span>} */}
            </div>
          );
        })}
      </div>
    </div>
  );
}
NextProject.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  formData: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
    location: PropTypes.string,
    prepared_by: PropTypes.string,
    representative: PropTypes.string,
    checked_by: PropTypes.string,
  }),
  refs: PropTypes.arrayOf(PropTypes.object),
  _handleChange: PropTypes.func,
  _setActiveNextProjectInput: PropTypes.func,
  _handleSubmit: PropTypes.func,
};

NextProject.defaultProps = {
  fields: [],
  formData: [],
  refs: [],
  _handleChange: () => {},
  _setActiveInput: () => {},
  _handleSubmit: () => {},
};

export default NextProject;
