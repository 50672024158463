import { hot } from "react-hot-loader/root"
import React, { Component } from "react"
import { Row, Col, Spin, Icon, Alert } from "antd"
import { jsPDF } from "jspdf"
import $ from "jquery"
import html2canvas from "html2canvas"
import { Modal, Button } from "antd"

import "./App.css"
import "antd/dist/antd.css"
import Header from "./Header/Header"
import Project from "./Project/Project"
import NextProject from "./NextProject/NextProject"
import Attendees from "./Attendees/Attendees"
import Minutes from "./Minutes/Minutes"
import Swal from "sweetalert2"
import { getTokenOrRefresh } from "./token_util"
import AdditionalDist from "./AdditionalDist/AdditionalDist"
import axios from "axios"
import LoadingSpinner from "./Spinner/spinner"
import ProjectModal from "./Project/ProjectModal"
import AttendeesModal from "./Attendees/AttendeesModal"
import MinutesModal from "./Minutes/MinutesModal"
import NextProjectModal from "./NextProject/NextProjectModal"
import { fabric } from "fabric"
import moment from "moment"

// initalizing speechsdk
const speechsdk = require("microsoft-cognitiveservices-speech-sdk")

//Global variables

//storing different sections in variables for reference
const PROJECT = 1
const NEXT_MEETING = 2
const ATTENDEES = 3
const MINUTES = 4
const APOLOGIES = 5

let recognizer // for azure sdk
let i = 0 //for minute label ref loop
let meeting_id // to get meeting id from url
let type // to get type for submeetings from url
let is_email_type // to get is_email_type  for
let is_email_time
let refjson
let labels = []
let nos = []
let pauseTimer

class App extends Component {
  // creating ref for autoscroll to prominent position when in focus
  projectRef = React.createRef()
  nextProjectRef = React.createRef()
  minutesRef = React.createRef()
  attendeesRef = React.createRef()
  canvasRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      recordedString: null,
      isRecognizing: false,
      recognizeCount: 0,
      newBlankField: false,
      rawText: "",
      activeInput: null,
      isRecording: false,
      loading: false,
      forceStopped: false,
      formData: {},
      inputHistory: [],
      showModal: false,
      helpModal: false,
      minutesModal: false,
      debugMode: false,
      error: null,
      selectedProject: PROJECT,
      projectRefs: [],
      projectLabelRefs: [],
      nextProjectLabelRefs: [],
      minuteRefs: [],
      dateRefs: [],
      ownerRefs: [],
      statusRefs: [],
      nextProjectRefs: [],
      attendeeFieldList: [],
      apologiesList: [],
      inAttendees: 0,
      inApologies: 0,
      recording: 0,
      recordAuto: 0,
      onAttendeesTable: 0,
      onApologiesTable: 0,
      bulletPoints: false,
      sequencePoints: false,
      sequenceNumber: 0,
      nestedSeq: false,
      nestedPoint: false,
      newLineOnBullets: false,
      newLineOnSequence: false,
      space: "\u0020\u0020\u0020",
      checkBullet: true,
      lcValue: "en-IN",
      activeEle: null,
      labelRefs: [],
      labelRef: [],
      labelButton: true,
      lines: 0,
      meeting_data: {},
      isLoading: true,
      heightOfMinute: "",
      attendeesList: [],
      additionalDistributionList: [],
      pdf: "",
      checkDataForMinutes: {},
      checkDataCounter: {},
      minuteLineCount: {},
      refjson: {},
      showModalProject: false,
      helpModalAttendees: false,
      helpModalApologies: false,
      helpModalMinutes: false,
      helpModalNextProject: false,
      showModalSubmit: false,
      selectedFile: null,
      highlightedTables: [],
      files: [],
      refHeight: null,
      refWidth: null,
      submitted: false,
    }
    this.handleFileChange = this.handleFileChange.bind(this)
  }

  handleCheckboxChange = (tableNumber) => {
    const { highlightedTables } = this.state
    const updatedHighlightedTables = [...highlightedTables]
    if (updatedHighlightedTables.includes(tableNumber)) {
      updatedHighlightedTables.splice(
        updatedHighlightedTables.indexOf(tableNumber),
        1
      )
    } else {
      updatedHighlightedTables.push(tableNumber)
    }
    this.setState({ highlightedTables: updatedHighlightedTables })
  }

  async componentDidMount() {
    const inputs = {}
    const inputHistory = {}

    window.scrollTo(0, 0)
    const searchParams = new URLSearchParams(document.location.search)
    meeting_id = { meeting_id: searchParams.get("meeting_id") } // getting meeting id from URL
    type = { type: searchParams.get("type") } // getting type from URL
    is_email_type = { is_email_type: searchParams.get("is_email_type") } // getting is_email_type from URL
    is_email_time = { is_email_time: searchParams.get("is_email_time") } // getting is_email_time from URL

    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URI}/api/get-meeting-json-file/${meeting_id.meeting_id}/${type.type}`
      )

      if (data.data) {
        refjson = data.data
        this.setState({ refjson })
      }
    } catch (error) {
      Swal.fire({
        timer: 5000,
        icon: "error",
        text: error,
        showCancelButton: false,
        showConfirmButton: false,
      })
    }

    try {
      // getting meeting data from an API
      const data = await axios.get(
        `${process.env.REACT_APP_API_URI}/api/get-meeting-data/${meeting_id.meeting_id}/${type.type}`
      )

      if (data.data.success) {
        this.setState({
          meeting_data: data.data.data,
          isLoading: false,
        })

        if (data.data.data) {
          let field_label_color = data.data.data.field_label_colour
          let field_label_text_colour = data.data.data.field_label_text_colour

          let section_label_colour = data.data.data.section_label_colour
          let backgroundColor = data.data.data.background
          let section_label_text_colour =
            data.data.data.section_label_text_colour
          let font_family = data.data.data.font_family
          let font_size = data.data.data.font_size
          let bold = data.data.data.bold
          let italic = data.data.data.italic
          let underline = data.data.data.underline
          let textInput = data.data.data.textInput

          // document.documentElement.style.setProperty(
          //   "--body-background-color",
          //   backgroundColor.toString()
          // );
          document.body.style.backgroundColor = backgroundColor.toString()
          document.documentElement.style.setProperty(
            "--field-label-color",
            field_label_color.toString()
          )
          document.documentElement.style.setProperty(
            "--field-label-text-color",
            field_label_text_colour.toString()
          )
          document.documentElement.style.setProperty(
            "--module-lable-color",
            section_label_colour.toString()
          )
          document.documentElement.style.setProperty(
            "--module-title-text-color",
            section_label_text_colour.toString()
          )
          // Set font family if not null
          if (font_family !== null && font_family !== undefined) {
            document.body.style.setProperty(
              "--font-family",
              font_family.toString()
            )
          }

          // Set font size if not null
          if (font_size !== null && font_size !== undefined) {
            document.body.style.setProperty("--font-size", font_size.toString())
          }

          // Set font weight if not null
          if (bold !== null && bold !== undefined) {
            document.body.style.setProperty(
              "--font-weight",
              bold.toString() === "on" ? "bold" : "normal"
            )
          }

          // Set font style if not null
          if (italic !== null && italic !== undefined) {
            document.body.style.setProperty(
              "--font-style",
              italic.toString() === "on" ? "italic" : "normal"
            )
          }

          // Set text decoration if not null
          if (underline !== null && underline !== undefined) {
            document.body.style.setProperty(
              "--text-decoration",
              underline.toString() === "on" ? "underline" : "none"
            )
          }

          // Set text input value if not null
          if (textInput !== null && textInput !== undefined) {
            document.documentElement.style.setProperty(
              "--text-input",
              textInput.toString()
            )
          }
          // await this.loadData()
          const {
            organization_logo_transform,
            text_input_transform,
            product_logo_transform,
          } = data.data.data

          const orgCoords = organization_logo_transform
            ? JSON.parse(organization_logo_transform)
            : {
                position_left: "0",
                position_top: "0",
                width: "50",
                height: "50",
                vertical_scale: "1",
                horizontal_scale: "1",
                canwidth: "800",
                canheight: "300",
              }
          const textCoords = text_input_transform
            ? JSON.parse(text_input_transform)
            : {
                position_left: "0",
                position_top: "0",
                width: "50",
                height: "10",
                vertical_scale: "1",
                horizontal_scale: "1",
                fontSize: "36",
                fontFamily: "Arial",
                fill: "#000",
                fontWeight: "bold",
                fontStyle: "italic",
                underline: "true",
              }
          const prodCoords = product_logo_transform
            ? JSON.parse(product_logo_transform)
            : {
                position_left: "0",
                position_top: "0",
                width: "50",
                height: "50",
                vertical_scale: "1",
                horizontal_scale: "1",
                canwidth: "800",
                canheight: "300",
              }

          // Set canvas width based on the condition
          const canvas = new fabric.Canvas("canvas", {
            width: this.canvasRef.current.clientWidth,
            height: this.canvasRef.current.clientHeight,
          })

          this.setState({
            refWidth: this.canvasRef.current.clientWidth,
            refHeight: this.canvasRef.current.clientHeight,
          })
          // Add organization logo

          if (data?.data?.data?.meeting_organization_logo) {
            fabric.Image.fromURL(
              data.data.data.meeting_organization_logo,
              function(img) {
                img.set({
                  width:
                    (parseFloat(orgCoords.width) * parseFloat(canvas.width)) /
                    parseFloat(orgCoords.canwidth) /
                    parseFloat(orgCoords.horizontal_scale),
                  height:
                    (parseFloat(orgCoords.height) * parseFloat(canvas.height)) /
                    parseFloat(orgCoords.canheight) /
                    parseFloat(orgCoords.vertical_scale),
                  left:
                    canvas.width * (parseFloat(orgCoords.position_left) / 100),
                  top: parseFloat(orgCoords.position_top) * 3,
                  scaleX: parseFloat(orgCoords.horizontal_scale),
                  scaleY: parseFloat(orgCoords.vertical_scale),

                  hasControls: false, // Disable controls
                  hasBorders: false, // Disable borders
                  lockMovementX: true,
                  lockMovementY: true,
                })

                canvas.add(img)
              }
            )
          }

          // Add text input
          if (data?.data?.data?.textInput) {
            const text = new fabric.Text(data.data.data.textInput, {
              width:
                (parseFloat(textCoords.width) * parseFloat(canvas.width)) /
                parseFloat(textCoords.canwidth) /
                parseFloat(textCoords.horizontal_scale),
              height:
                (parseFloat(textCoords.height) * parseFloat(canvas.height)) /
                parseFloat(textCoords.canheight) /
                parseFloat(textCoords.vertical_scale),
              fontFamily: textCoords?.fontFamily ?? "Arial",
              fill: textCoords.fill,
              fontSize: parseInt(textCoords.fontSize),
              left: canvas.width * (parseFloat(textCoords.position_left) / 100),
              top: parseFloat(textCoords.position_top) * 3,
              fontWeight: textCoords.fontWeight ?? "300",
              fontStyle: textCoords.fontStyle ?? "normal",
              underline: textCoords.underline == "true" ? true : false,
              scaleX: parseFloat(textCoords.horizontal_scale),
              scaleY: parseFloat(textCoords.vertical_scale),
              hasControls: false, // Disable controls
              hasBorders: false, // Disable borders
              lockMovementX: true,
              lockMovementY: true,
            })
            canvas.add(text)
          }

          // Add product logo
          if (data?.data?.data?.meeting_product_logo) {
            fabric.Image.fromURL(data.data.data.meeting_product_logo, function(
              img
            ) {
              let imageWidthPer = (prodCoords.width * 100) / prodCoords.canwidth
              let imageHeightPer =
                (prodCoords.height * 100) / prodCoords.canheight

              img.set({
                // width:
                //   (parseFloat(prodCoords.width) * canvas.width) /
                //   parseFloat(prodCoords.canwidth) /
                //   parseFloat(prodCoords.horizontal_scale),
                width:
                  (imageWidthPer * canvas.width) /
                  100 /
                  prodCoords.horizontal_scale,
                // height:
                //   (parseFloat(prodCoords.height) * canvas.height) /
                //   parseFloat(prodCoords.canheight) /
                //   parseFloat(prodCoords.vertical_scale),
                height:
                  (imageHeightPer * canvas.height) /
                  100 /
                  prodCoords.vertical_scale,

                left: canvas.width * (prodCoords.position_left / 100),
                top: prodCoords.position_top * 3,
                scaleX: prodCoords.horizontal_scale,
                scaleY: prodCoords.vertical_scale,
                hasControls: false, // Disable controls
                hasBorders: false, // Disable borders
                lockMovementX: true,
                lockMovementY: true,
              })
              canvas.add(img)
            })
          }
        }
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        timer: 5000,
        icon: "error",
        text: `There is an issue while connecting!, Error: ${error}`,
        showCancelButton: false,
        showConfirmButton: false,
      })
    }

    // speechsdk setup

    recognizer = await this.stt()
    // getting token from azure
    const tokenRes = await getTokenOrRefresh()
    if (tokenRes.authToken === null) {
      this.setState({
        recordedString: "FATAL_ERROR: " + tokenRes.error,
      })
    }
    // Opening help modal on startup
    this.setState({ helpModal: true })

    //getting meeting data from an API or initializing it with blank fields
    let meeting_json = this.state.meeting_data.meeting_json
      ? JSON.parse(this.state.meeting_data.meeting_json)
      : ""
    let date_status = Number(this.state.meeting_data.date_status)
    let meeting_status = Number(this.state.meeting_data.meeting_status)
    let owner_status = Number(this.state.meeting_data.owner_status)

    // getting attendees list from an API
    let attendeesList = this.state?.meeting_data
      ? this.state?.meeting_data?.attendees?.filter(
          (attendee) => attendee.roles !== "additional_distribution"
        )
      : []

    // getting additional distribution list from an API
    let additionalDistributionList = this.state.meeting_data
      ? this.state.meeting_data.additional_distribution
      : []

    this.setState({
      attendeeFieldList: attendeesList,
      additionalDistributionList,
      apologiesList: this.state.meeting_data.apologies,
      date_status,
      meeting_status,
      owner_status,
    })

    // Initialize inputs array with blank values or if updating then with API data

    // let projectLabels = [];

    // //  storing labels
    // this.state.refjson &&
    //   this.state.refjson.fields.map((each) => {
    //     projectLabels.push(each.label);
    //   });
    //initializing input fields of project section
    this.state.refjson &&
      this.state.refjson.fields.forEach((each) => {
        inputs[each.name] =
          meeting_json && meeting_json.form_data[each.name]
            ? meeting_json.form_data[each.name]
            : ""
        inputHistory[each.name] =
          meeting_json && meeting_json.input_history[each.name]
            ? meeting_json.input_history[each.name]
            : ""
      })

    // creating ref for project fields
    const projectRefs =
      this.state.refjson &&
      this.state.refjson.fields.map((each) => React.createRef())

    //initializing input fields of next project section
    this.state.refjson &&
      this.state.refjson.nextProjectFields.forEach((each) => {
        inputs[each.name] =
          meeting_json && meeting_json.form_data[each.name]
            ? meeting_json.form_data[each.name]
            : ""
        inputHistory[each.name] =
          meeting_json && meeting_json.input_history[each.name]
            ? meeting_json.input_history[each.name]
            : ""
      })

    // creating ref for next project fields
    const nextProjectRefs =
      this.state.refjson &&
      this.state.refjson.nextProjectFields.map((each) => React.createRef())
    //initializing input fields of minute section
    this.state.refjson &&
      this.state.refjson.minutesFields.forEach((each) => {
        each.minutes.forEach((subEach) => {
          inputs[subEach.minute] =
            meeting_json && meeting_json.form_data[subEach.minute]
              ? meeting_json.form_data[subEach.minute]
              : ""
          inputs[subEach.owner] =
            meeting_json && meeting_json.form_data[subEach.owner]
              ? meeting_json.form_data[subEach.owner]
              : ""
          inputs[subEach.date] =
            meeting_json && meeting_json.form_data[subEach.date]
              ? meeting_json.form_data[subEach.date]
              : ""
          inputs[subEach.status] =
            meeting_json && meeting_json.form_data[subEach.status]
              ? meeting_json.form_data[subEach.status]
              : ""
          inputs[subEach.minuteLineCount] =
            meeting_json && meeting_json.form_data[subEach.minuteLineCount]
              ? meeting_json.form_data[subEach.minuteLineCount]
              : []

          this.state.minuteLineCount[subEach.minuteLineCount] =
            meeting_json && meeting_json.form_data[subEach.minuteLineCount]
              ? meeting_json.form_data[subEach.minuteLineCount]
              : []
          inputs[subEach.minuteLineHeight] =
            meeting_json && meeting_json.form_data[subEach.minuteLineHeight]
              ? meeting_json.form_data[subEach.minuteLineHeight]
              : 48
          inputHistory[subEach.minute] =
            meeting_json && meeting_json.input_history[subEach.minute]
              ? meeting_json.input_history[subEach.minute]
              : ""

          inputHistory[subEach.owner] =
            meeting_json && meeting_json.input_history[subEach.owner]
              ? meeting_json.input_history[subEach.owner]
              : ""

          inputHistory[subEach.date] =
            meeting_json && meeting_json.input_history[subEach.date]
              ? meeting_json.input_history[subEach.date]
              : ""
          inputHistory[subEach.status] =
            meeting_json && meeting_json.input_history[subEach.status]
              ? meeting_json.input_history[subEach.status]
              : ""
          // checking if there is any data in minute field or not
          // it is used for printing [Updated on : timestamp] in minute fields
          // we are setting checkDataForMinutes : 1, if it has data and after updating minute field we are setting  checkDataCounter : 1 for that input field, so that we can track and not to print timestamp again in that session.
          if (
            this.state.meeting_data.meeting_json &&
            meeting_json.form_data[subEach.minute]
          ) {
            this.setState((prevState) => ({
              checkDataForMinutes: {
                ...this.state.checkDataForMinutes,
                [subEach.minute]: 1,
              },
              checkDataCounter: {
                ...this.state.checkDataCounter,
                [subEach.minute]: 0,
              },
            }))
          } else if (
            !this.state.meeting_data.meeting_json ||
            (this.state.meeting_data.meeting_json &&
              !meeting_json.form_data[subEach.minute])
          ) {
            this.setState((prevState) => ({
              checkDataForMinutes: {
                ...this.state.checkDataForMinutes,
                [subEach.minute]: 0,
              },
            }))
          }
        })
      })
    let minuteRefs = []
    let dateRefs = []
    let ownerRefs = []
    let statusRefs = []
    let labelRefs = []
    let labelRef = []

    //  storing labels
    this.state.refjson &&
      this.state.refjson.minutesFields.map((each) => {
        labels.push(each.label)
        nos.push(each.no)
      })

    // initializing title fields of minute section
    this.state.refjson &&
      this.state.refjson.minutesFields.forEach((each) => {
        inputs[each.title] =
          meeting_json && meeting_json.form_data[each.title]
            ? meeting_json.form_data[each.title]
            : labels[i]
        inputHistory[each.title] =
          meeting_json && meeting_json.input_history[each.title]
            ? meeting_json.input_history[each.title]
            : labels[i]
        i++
      })

    // creating ref for minute section fields

    this.state.refjson &&
      this.state.refjson.minutesFields.map((each) => {
        labelRefs.push((each.label = React.createRef()))
      })

    this.state.refjson &&
      this.state.refjson.minutesFields.map((each) => {
        labelRef.push((each.no = React.createRef()))
      })

    this.state.refjson &&
      this.state.refjson.minutesFields.map((each, index) => {
        minuteRefs.push(...each.minutes.map((subEach) => React.createRef()))
      })
    this.state.refjson &&
      this.state.refjson.minutesFields.map((each, index) => {
        ownerRefs.push(...each.minutes.map((subEach) => React.createRef()))
      })
    this.state.refjson &&
      this.state.refjson.minutesFields.map((each, index) => {
        dateRefs.push(...each.minutes.map((subEach) => React.createRef()))
      })
    this.state.refjson &&
      this.state.refjson.minutesFields.map((each, index) => {
        statusRefs.push(...each.minutes.map((subEach) => React.createRef()))
      })

    this.setState({
      formData: inputs,
      inputHistory,
      projectRefs,
      nextProjectRefs,
      minuteRefs,
      dateRefs,
      ownerRefs,
      statusRefs,
      labelRefs,
      labelRef,
    })
  }
  async loadData() {
    const { meeting_data, refHeight, refWidth } = this.state
    const clientHeight = refHeight
    const clientWidth = refWidth

    const {
      organization_logo_transform,
      text_input_transform,
      product_logo_transform,
    } = meeting_data
    const orgCoords = organization_logo_transform
      ? JSON.parse(organization_logo_transform)
      : {
          position_left: "0",
          position_top: "0",
          width: "50",
          height: "50",
          vertical_scale: "1",
          horizontal_scale: "1",
          canwidth: "800",
          canheight: "300",
        }

    const textCoords = text_input_transform
      ? JSON.parse(text_input_transform)
      : {
          position_left: "0",
          position_top: "0",
          width: "0",
          height: "0",
          vertical_scale: "1",
          horizontal_scale: "1",
          fontSize: "36",
          fontFamily: "Arial",
          fill: "#000",
          fontWeight: "bold",
          fontStyle: "italic",
          underline: "true",
        }
    const prodCoords = product_logo_transform
      ? JSON.parse(product_logo_transform)
      : {
          position_left: "0",
          position_top: "0",
          width: "50",
          height: "50",
          vertical_scale: "1",
          horizontal_scale: "1",
          canwidth: "800",
          canheight: "300",
        }
    const isHelpModalOpen =
      this.state.showModalProject ||
      this.state.helpModalApologies ||
      this.state.helpModalAttendees ||
      this.state.helpModalMinutes ||
      this.state.helpModalNextProject

    const canvasWidth = isHelpModalOpen ? 1000 : clientWidth
    const canvas = new fabric.Canvas("canvas", {
      width: canvasWidth,
      height: clientHeight,
    })
    // Add organization logo
    if (meeting_data.meeting_organization_logo) {
      fabric.Image.fromURL(meeting_data.meeting_organization_logo, function(
        img
      ) {
        img.set({
          width:
            (parseFloat(orgCoords.width) * parseFloat(canvas.width)) /
            parseFloat(orgCoords.canwidth) /
            parseFloat(orgCoords.horizontal_scale),
          height:
            (parseFloat(orgCoords.height) * parseFloat(canvas.height)) /
            parseFloat(orgCoords.canheight) /
            parseFloat(orgCoords.vertical_scale),
          left: canvas.width * (parseFloat(orgCoords.position_left) / 100),
          top: parseFloat(orgCoords.position_top) * 3,
          scaleX: parseFloat(orgCoords.horizontal_scale),
          scaleY: parseFloat(orgCoords.vertical_scale),

          hasControls: false, // Disable controls
          hasBorders: false, // Disable borders
          lockMovementX: true,
          lockMovementY: true,
        })
        canvas.add(img)
      })
    }
    // Add text input
    if (meeting_data.textInput) {
      const text = new fabric.Text(meeting_data.textInput, {
        width:
          (parseFloat(textCoords.width) * parseFloat(canvas.width)) /
          parseFloat(textCoords.canwidth) /
          parseFloat(textCoords.horizontal_scale),
        height:
          (parseFloat(textCoords.height) * parseFloat(canvas.height)) /
          parseFloat(textCoords.canheight) /
          parseFloat(textCoords.vertical_scale),
        fontSize: parseInt(textCoords.fontSize),
        fontFamily: textCoords?.fontFamily ?? "Arial",
        fill: textCoords.fill,
        left: canvas.width * (parseFloat(textCoords.position_left) / 100),
        fontWeight: textCoords.fontWeight,
        fontStyle: textCoords.fontStyle,
        underline: textCoords.underline == "true" ? true : false,
        top: parseFloat(textCoords.position_top) * 3,
        scaleX: parseFloat(textCoords.horizontal_scale),
        scaleY: parseFloat(textCoords.vertical_scale),
        hasControls: false, // Disable controls
        hasBorders: false, // Disable borders
        lockMovementX: true,
        lockMovementY: true,
      })

      canvas.add(text)
    }

    // Add product logo
    if (meeting_data.meeting_product_logo) {
      fabric.Image.fromURL(meeting_data.meeting_product_logo, function(img) {
        img.set({
          width:
            (parseFloat(prodCoords.width) * parseFloat(canvas.width)) /
            parseFloat(prodCoords.canwidth) /
            parseFloat(prodCoords.horizontal_scale),
          height:
            (parseFloat(prodCoords.height) * parseFloat(canvas.height)) /
            parseFloat(prodCoords.canheight) /
            parseFloat(prodCoords.vertical_scale),
          left: canvas.width * (parseFloat(prodCoords.position_left) / 100),
          top: parseFloat(prodCoords.position_top) * 3,
          scaleX: parseFloat(prodCoords.horizontal_scale),
          scaleY: parseFloat(prodCoords.vertical_scale),
          hasControls: false, // Disable controls
          hasBorders: false, // Disable borders
          lockMovementX: true,
          lockMovementY: true,
        })
        canvas.add(img)
      })
    }
  }

  //speech-recognition setup

  async stt() {
    const tokenObj = await getTokenOrRefresh()

    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    )
    speechConfig.speechRecognitionLanguage = this.state.lcValue

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput()
    const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig)
    return recognizer
  }

  handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files)
    this.setState({ files: selectedFiles })
  }
  async updateFormData() {
    const { files } = this.state
    const formData = new FormData()
    files.forEach((file, index) => {
      formData.append(`files[${index + 1}]`, file)
    })
    const headers = {
      "Content-Type": "multipart/form-data",
    }
    formData.append("type", type.type)
    formData.append("meeting_id", meeting_id.meeting_id)
    formData.append("is_email_time", is_email_time.is_email_time)
    formData.append("is_email_type", is_email_type.is_email_type)
    formData.append(
      "add_dist_id",
      this.state.additionalDistributionList.map((i) => i.id).toString()
    )
    formData.append(
      "attendies_id",
      this.state.attendeeFieldList.map((i) => i.id).toString()
    )
    formData.append(
      "apologies_id",
      this.state.apologiesList.map((i) => i.id).toString()
    )
    formData.append(
      "meeting_json",
      JSON.stringify({
        form_data: this.state.formData,
        input_history: this.state.inputHistory,
      })
    )

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/update-meeting-data`,
        formData,
        headers
      )
      this.setState({ isLoading: false })
      await this.loadData()
    } catch (error) {
      console.error("Error updating form data:", error)
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      formData,
      activeInput,
      inputHistory,
      recognizeCount,
      isRecognizing,
      rawText,
    } = this.state
    if (
      prevState.showModalProject !== this.state.showModalProject ||
      prevState.helpModalApologies !== this.state.helpModalApologies ||
      prevState.helpModalAttendees !== this.state.helpModalAttendees ||
      prevState.helpModalMinutes !== this.state.helpModalMinutes ||
      prevState.helpModalNextProject !== this.state.helpModalNextProject ||
      prevState.showModalSubmit !== this.state.showModalSubmit
    )
      await this.loadData()
    // if language is changed by user
    if (prevState.lcValue !== this.state.lcValue) {
      recognizer = await this.stt()
    }

    // if input field is changed, and next field is empty, using these states in continuous input population
    if (
      prevState.activeInput !== this.state.activeInput &&
      !formData[activeInput]
    ) {
      this.setState({ newBlankField: true, recognizeCount: 0 })
    }

    // if field has been changed and new field has some value in it.
    if (
      prevState.activeInput !== this.state.activeInput &&
      formData[activeInput]
    ) {
      this.setState({ newBlankField: false })
    }

    // replacing text with new arrived raw text and recognized text
    const replaceText = (currText) => {
      currText = currText.replace(
        `${inputHistory[activeInput][inputHistory[activeInput].length - 1]} `,
        ""
      )

      formData[activeInput] = currText
      if (typeof inputHistory[activeInput] == "object")
        inputHistory[activeInput].pop()

      this.setState({ ...formData, ...inputHistory })
    }

    // when rawtext is changing - rawtext is coming from azure API which is raw, not capitalized and not punctuated.
    // raw texts are coming in buffers so we are storing it in array as it is coming and also replacing with new arrived result at the same time after printing it in field.
    if (prevState.rawText !== rawText) {
      // for minutes section
      if (this.state.selectedProject === MINUTES) {
        if (this.state.newBlankField && recognizeCount === 0) {
          //for blank fields
          let currText = formData[activeInput]
          if (currText) {
            replaceText(currText)
          }
        } else if (recognizeCount > 0) {
          // if field has data in it.
          let currText = formData[activeInput]

          let str = inputHistory[activeInput]
            ? inputHistory[activeInput][inputHistory[activeInput].length - 1]
            : ""
          let newStr = str ? str.charAt(str.length - 1) : ""

          // we are checking if it punctuated result or not until then replacing last added array with new arrived results.
          if (
            currText &&
            newStr !== "." &&
            newStr !== "?" &&
            newStr !== "!" &&
            newStr !== ","
          ) {
            replaceText(currText)
          }
        }
      } else {
        // for other sections, not for minutes
        if (recognizeCount === 0 && this.state.newBlankField) {
          let currText = formData[activeInput]

          if (currText) {
            replaceText(currText)
          }
        } else {
          let currText = formData[activeInput]
          let str =
            inputHistory[activeInput][inputHistory[activeInput].length - 1]
          let newStr = str && str.charAt(str.length - 1)

          if (
            currText &&
            newStr !== "." &&
            newStr !== "?" &&
            newStr !== "!" &&
            newStr !== ","
          ) {
            replaceText(currText)
          }
        }
      }
    }

    // if recognized (punctuated) results arrived from azure, we are replacing it with non punctuated result.
    if (prevState.isRecognizing !== isRecognizing && !isRecognizing) {
      let currText = formData[activeInput]

      let anotherStr = formData[activeInput]?.charAt(
        formData[activeInput]?.length - 2
      )

      if (activeInput.includes("date")) {
        if (formData[activeInput].includes("/") || !formData[activeInput]) {
          return
        } else {
          replaceText(currText)
        }
      } else if (activeInput.includes("title")) {
        if (currText) {
          return
        }
      } else if (
        currText &&
        anotherStr !== "." &&
        anotherStr !== "?" &&
        anotherStr !== "!" &&
        anotherStr !== ","
      ) {
        replaceText(currText)
      }
    }
  }

  // processing commands
  _processCommand = (recordedString) => {
    this.setState({ recordAuto: 0, onApologiesTable: 0 })
    const {
      activeInput,
      formData,
      inputHistory,
      projectRefs,
      nextProjectRefs,
      minuteRefs,
      ownerRefs,
      statusRefs,
      dateRefs,
      labelRef,
      labelRefs,
    } = this.state

    let attendeeNumber = 0
    let apologiesNumber = 0

    // if Attendees section is selected and serial number has been spoken, commands will be selected as AttendeesList and will process that command and accordingly with apologiesList
    // try {
    // eslint-disable-next-line react/destructuring-assignment
    if (
      this.state.inAttendees === 1 &&
      this.state.selectedProject === ATTENDEES
    ) {
      const regex = /\d/g
      if (regex.test(recordedString)) {
        // eslint-disable-next-line radix
        attendeeNumber = Number(recordedString)
        // eslint-disable-next-line no-param-reassign
        recordedString = "AttendeeList"
        // this.setState({ recordedString: "AttendeeList" })
      }
    } else if (
      this.state.inApologies === 1 &&
      this.state.selectedProject === APOLOGIES
    ) {
      const regex = /\d/g

      if (regex.test(recordedString)) {
        // eslint-disable-next-line radix
        apologiesNumber = Number(recordedString)
        // eslint-disable-next-line no-param-reassign
        recordedString = "ApologiesList"
        // this.setState({ recordedString })
      }
    }
    // } catch (e) {
    //   console.log(e.get)
    // }

    const getApologies = this.state.apologiesList
    const getAttendees = this.state.attendeeFieldList
    console.log(recordedString)
    // commands cases
    switch (recordedString) {
      // toggle debug mode on or off
      case "Toggle debug.":
        this._onDebugModeChange()
        break
      // for going to next fields
      case "Next.":
      case "Go to next.":
        this.setState({ recordAuto: 1 })

        if (this.state.selectedProject === 1) {
          const index = this._goToNextInput(activeInput, projectRefs)

          if (projectRefs.length - 1 === index) {
            this.setState({ recordAuto: 1 })
            this.setState({
              selectedProject: ATTENDEES,
              inAttendees: 1,
              onAttendeesTable: 1,
            })
            this.attendeesRef.current.scrollIntoView()
          } else {
            this.setState({
              activeInput: projectRefs[index + 1].current.name,
            })
            projectRefs[index + 1].current.focus()
          }
        } else if (this.state.selectedProject === 2) {
          const index = this._goToNextInput(activeInput, nextProjectRefs)
          if (nextProjectRefs.length - 1 === index) {
            this.setState({
              activeInput: nextProjectRefs[0].current.name,
            })
            nextProjectRefs[0].current.focus()
          } else {
            this.setState({
              activeInput: nextProjectRefs[index + 1].current.name,
            })
            nextProjectRefs[index + 1].current.focus()
          }
        } else if (this.state.selectedProject === 4) {
          if (activeInput.includes("minute")) {
            const index = this._goToNextInput(activeInput, minuteRefs)
            {
              if (this.state.owner_status === 1) {
                this.setState({ activeInput: ownerRefs[index].current.name })
                ownerRefs[index].current.focus()
              } else if (this.state.date_status === 1) {
                this.setState({ activeInput: dateRefs[index].current.name })
                dateRefs[index].current.focus()
              } else if (this.state.meeting_status === 1) {
                this.setState({ activeInput: statusRefs[index].current.name })
                statusRefs[index].current.focus()
              } else if (minuteRefs.length - 1 === index) {
                this.setState({ activeInput: nextProjectRefs[0].current.name })
                nextProjectRefs[0].current.focus()
              } else {
                this.setState({
                  activeInput: minuteRefs[index + 1].current.name,
                })
                minuteRefs[index + 1].current.focus()
              }
            }
          } else if (activeInput.includes("owner")) {
            const index = this._goToNextInput(activeInput, ownerRefs)
            {
              if (this.state.date_status === 1) {
                this.setState({ activeInput: dateRefs[index].current.name })
                dateRefs[index].current.focus()
              } else if (this.state.meeting_status === 1) {
                this.setState({ activeInput: statusRefs[index].current.name })
                statusRefs[index].current.focus()
              } else if (minuteRefs.length - 1 === index) {
                this.setState({ activeInput: nextProjectRefs[0].current.name })
                nextProjectRefs[0].current.focus()
              } else {
                this.setState({
                  activeInput: minuteRefs[index + 1].current.name,
                })
                minuteRefs[index + 1].current.focus()
              }
            }
          } else if (activeInput.includes("date")) {
            const index = this._goToNextInput(activeInput, dateRefs)
            if (this.state.meeting_status === 1) {
              this.setState({ activeInput: statusRefs[index].current.name })
              statusRefs[index].current.focus()
            } else if (minuteRefs.length - 1 === index) {
              this.setState({ activeInput: nextProjectRefs[0].current.name })
              nextProjectRefs[0].current.focus()
            } else {
              this.setState({
                activeInput: minuteRefs[index + 1].current.name,
              })
              minuteRefs[index + 1].current.focus()
            }
          } else if (activeInput.includes("status")) {
            const index = this._goToNextInput(activeInput, statusRefs)
            if (statusRefs.length - 1 === index) {
              this.setState({ activeInput: nextProjectRefs[0].current.name })
              nextProjectRefs[0].current.focus()
            } else {
              this.setState({
                activeInput: minuteRefs[index + 1].current.name,
              })
              minuteRefs[index + 1].current.focus()
            }
          }
        }

        break
      // going in previous fields
      case "Back.":
      case "Go back.":
      case "Previous.":
      case "Go to previous.":
        this.setState({ recordAuto: 1 })
        if (this.state.selectedProject === 1) {
          const currIndex = this._goToNextInput(activeInput, projectRefs)
          if (currIndex === 0) {
            this.setState({
              activeInput: projectRefs[projectRefs.length - 1].current.name,
            })
            projectRefs[projectRefs.length - 1].current.focus()
          } else {
            this.setState({
              activeInput: projectRefs[currIndex - 1].current.name,
            })
            projectRefs[currIndex - 1].current.focus()
          }
        } else if (this.state.selectedProject === 2) {
          const currIndex = this._goToNextInput(activeInput, nextProjectRefs)
          if (currIndex === 0) {
            this.setState({
              activeInput: statusRefs[statusRefs.length - 1].current.name,
            })
            statusRefs[statusRefs.length - 1].current.focus()
          } else {
            this.setState({
              activeInput: nextProjectRefs[currIndex - 1].current.name,
            })

            nextProjectRefs[currIndex - 1].current.focus()
          }
        } else if (this.state.selectedProject === 4) {
          if (activeInput.includes("minute")) {
            const currIndex = this._goToNextInput(activeInput, minuteRefs)
            if (currIndex === 0) {
              this.setState({ recordAuto: 1 })
              this.setState({
                selectedProject: ATTENDEES,
                inAttendees: 1,
                onAttendeesTable: 1,
              })
              this.attendeesRef.current.scrollIntoView()
            } else if (
              this.state.owner_status === 1 &&
              this.state.meeting_status === 0 &&
              this.state.date_status === 0
            ) {
              this.setState({
                activeInput: ownerRefs[currIndex - 1].current.name,
              })
              ownerRefs[currIndex - 1].current.focus()
            } else if (
              this.state.date_status === 1 &&
              this.state.meeting_status === 0
            ) {
              this.setState({
                activeInput: dateRefs[currIndex - 1].current.name,
              })
              dateRefs[currIndex - 1].current.focus()
            } else if (this.state.meeting_status === 1) {
              this.setState({
                activeInput: statusRefs[currIndex - 1].current.name,
              })
              statusRefs[currIndex - 1].current.focus()
            } else if (
              this.state.owner_status === 0 &&
              this.state.meeting_status === 0 &&
              this.state.date_status === 0
            ) {
              this.setState({
                activeInput: minuteRefs[currIndex - 1].current.name,
              })
              minuteRefs[currIndex - 1].current.focus()
            }
          } else if (activeInput.includes("owner")) {
            const currIndex = this._goToNextInput(activeInput, ownerRefs)
            this.setState({ activeInput: minuteRefs[currIndex].current.name })
            minuteRefs[currIndex].current.focus()
          } else if (activeInput.includes("date")) {
            const currIndex = this._goToNextInput(activeInput, dateRefs)
            if (this.state.owner_status === 1) {
              this.setState({ activeInput: ownerRefs[currIndex].current.name })
              ownerRefs[currIndex].current.focus()
            } else if (this.state.owner_status === 0) {
              this.setState({
                activeInput: minuteRefs[currIndex].current.name,
              })
              minuteRefs[currIndex].current.focus()
            }
          } else if (activeInput.includes("status")) {
            const currIndex = this._goToNextInput(activeInput, statusRefs)
            if (this.state.date_status === 1) {
              this.setState({ activeInput: dateRefs[currIndex].current.name })
              dateRefs[currIndex].current.focus()
            } else if (
              this.state.owner_status === 1 &&
              this.state.date_status === 0
            ) {
              this.setState({ activeInput: ownerRefs[currIndex].current.name })
              ownerRefs[currIndex].current.focus()
            } else if (
              this.state.owner_status === 0 &&
              this.state.date_status === 0
            ) {
              this.setState({
                activeInput: minuteRefs[currIndex].current.name,
              })
              minuteRefs[currIndex].current.focus()
            }
          }
        }
        break
      // Appends space at the end of input
      case "Space.":
        if (activeInput) {
          formData[activeInput] = `${formData[activeInput]} `
          this.setState((prevState) => ({
            ...formData,
            inputHistory: {
              ...prevState.inputHistory,
              [activeInput]: [...prevState.inputHistory[activeInput], " "],
            },
          }))
        }
        break
      // Clears currently active input element
      case "Clear.":
      case "Clear input.":
      case "Clear field.":
      case "Start again.":
      case "Delete.":
        if (activeInput) {
          if (this.state.selectedProject === MINUTES) {
            if (activeInput.includes("minute")) {
              if (
                window.confirm(
                  "Are you sure? \r\nYou won't be able to revert this!"
                ) === true
              ) {
                const timeElapsed = Date.now()
                const today = new Date(timeElapsed)

                formData[activeInput] =
                  "[Updated on: " +
                  moment().format("MMMM Do YYYY") +
                  "] " +
                  "\r\n"
                this.setState({ ...formData, recordAuto: 1 })

                this.setState({
                  checkDataForMinutes: {
                    ...this.state.checkDataForMinutes,
                    [activeInput]: 0,
                  },
                  checkDataCounter: {
                    ...this.state.checkDataCounter,
                    [activeInput]: 0,
                  },
                })
              } else {
                this.setState({ recordAuto: 1 })
              }
            } else {
              formData[activeInput] = ""
              this.setState({ ...formData, recordAuto: 1 })
            }
          } else {
            formData[activeInput] = ""
            this.setState({ ...formData, recordAuto: 1 })
          }
        }
        break
      // Resets form by clearing all the input fields
      case "Reset.":
      case "Reset form.":
      case "Clear form.": {
        const clearFields = {}
        this.state.refjson &&
          this.state.refjson.fields.forEach((each) => {
            clearFields[each.name] = ""
          })
        this.state.refjson &&
          this.state.refjson.nextProjectFields.forEach((each) => {
            clearFields[each.name] = ""
          })
        this.state.refjson &&
          this.state.refjson.minutesFields.forEach((each) => {
            each.minutes.forEach((subEach) => {
              clearFields[subEach.minute] = ""
              clearFields[subEach.owner] = ""
              clearFields[subEach.status] = ""
              clearFields[subEach.date] = ""
              clearFields[subEach.minuteLineCount] = []
              clearFields[subEach.minuteLineHeight] = ""
            })
          })
        this.setState({ formData: clearFields })
        break
      }
      // Deletes last string added to active input
      case "Remove.":
        this.setState({ recordAuto: 1 })
        if (activeInput) {
          let currText = formData[activeInput]
          if (currText) {
            currText = currText.replace(
              `${
                inputHistory[activeInput][inputHistory[activeInput].length - 1]
              } `,
              ""
            )

            formData[activeInput] = currText
            // set updated input
            this.setState({ ...formData })
            // remove that word from history as well.
            inputHistory[activeInput].pop()
          }
        }
        break
      // Submits form
      case "Go.":
      case "Submit.":
        // case "Submit form.":
        // this._handleSubmit()
        this.showModalSubmit()
        break
      // case "Go.":
      // case "Submit.":
      case "Submit form.":
        this._handleSubmit()
        break
      // Stops recording/listening to user commands
      case "Stop recording.":
      case "Stop listening.":
        this._stopRecording()
        break
      // Pauses recording/listening to user commands
      case "Pause recording.":
        // this.setState({ recordAuto: 0 });
        this._pauseRecording()
        break
      // Going to project section
      case "Project.":
      case "Go to project.":
        this.setState({ recordAuto: 1 })
        this.setState({ selectedProject: PROJECT })
        this.projectRef.current.scrollIntoView()
        this.state.projectRefs[0].current.focus()
        this.setState({
          activeInput: this.state.projectRefs[0].current.name,
        })
        break
      // Going to Minute sections
      case "Minutes.":
      case "Go to minutes.":
        this.setState({ recordAuto: 1 })
        this.setState({ selectedProject: MINUTES })
        this.setState({ activeInput: minuteRefs[0].current.name })
        minuteRefs[0].current.focus()
        this.minutesRef.current.scrollIntoView()
        break
      // going to Attendees table
      case "Attendees.":
      case "Go to attendees.":
        this.setState({ recordAuto: 1 })
        this.setState({
          selectedProject: ATTENDEES,
          inAttendees: 1,
          onAttendeesTable: 1,
        })
        this.setState({ inAttendees: 1, onAttendeesTable: 1 })
        this.attendeesRef.current.scrollIntoView()
        break
      // going to Apologies table
      case "Apologies.":
      case "Go to apologies.":
        this.setState({ recordAuto: 1 })
        // this.setState({ selectedProject: PROJECT });
        this.setState({ selectedProject: APOLOGIES })
        this.attendeesRef.current.scrollIntoView()
        this.setState({ inApologies: 1, onApologiesTable: 1 })
        break
      //Going to next project section
      case "Next meeting.":
      case "Go to next meeting.":
        this.setState({ recordAuto: 1 })
        this.setState({ selectedProject: NEXT_MEETING })
        this.nextProjectRef.current.scrollIntoView()
        this.setState({
          activeInput: this.state.nextProjectRefs[0].current.name,
        })
        this.state.nextProjectRefs[0].current.focus()
        break
      // if attendees section is selected and number is spoken then this command will be activated
      case "AttendeeList":
        this.setState({ recordAuto: 1 })
        this.state.attendeeFieldList.map((each, index) => {
          if (index + 1 == attendeeNumber) {
            getApologies.push(each)
            this.setState({ apologiesList: getApologies })
            getAttendees.splice(parseInt(index), 1)
            this.setState({ attendeeFieldList: getAttendees })
          }
        })
        break
      // if apologies section is selected and number is spoken then this command will be activated
      case "ApologiesList":
        this.setState({ recordAuto: 1 })

        this.state.apologiesList.map((each, index) => {
          if (index + 1 == apologiesNumber) {
            getAttendees.push(each)
            this.setState({ attendeeFieldList: getAttendees })
            getApologies.splice(parseInt(index), 1)
            this.setState({ apologiesList: getApologies })
          }
        })
        break
      // to start bullet points
      case "Start point.":
        if (activeInput.includes("minute")) {
          this.setState(
            {
              recordAuto: 1,
              bulletPoints: true,
              sequencePoints: false,
            },
            () => {
              const existingValue = formData[activeInput]
              const timeElapsed = Date.now()
              const today = new Date(timeElapsed)
              if (this.state.checkDataForMinutes[activeInput] == 0) {
                if (
                  this.state.inputHistory[activeInput].length == 0 &&
                  this.state.bulletPoints == true
                ) {
                  formData[activeInput] =
                    existingValue +
                    "[Updated on: " +
                    moment().format("MMMM Do YYYY") +
                    "] " +
                    "\r\n" +
                    "\u0020\u0020\u0020" +
                    "\u2022" +
                    " "
                  this.setState({ recordAuto: 1, checkBullet: false })
                } else if (
                  this.state.bulletPoints &&
                  this.state.sequencePoints == false
                ) {
                  formData[activeInput] =
                    existingValue +
                    "\r\n" +
                    `${this.state.space}` +
                    "\u2022" +
                    " "
                  this.setState({ recordAuto: 1, checkBullet: false })
                }
              } else if (this.state.checkDataForMinutes[activeInput] == 1) {
                if (
                  this.state.checkDataCounter[activeInput] == 0 &&
                  this.state.bulletPoints == true
                ) {
                  formData[activeInput] =
                    existingValue +
                    "\r\n" +
                    "[Updated on: " +
                    moment().format("MMMM Do YYYY") +
                    "] " +
                    "\r\n" +
                    "\u0020\u0020\u0020" +
                    "\u2022" +
                    " "
                  this.setState({ recordAuto: 1, checkBullet: false })
                  this.setState((prevState) => ({
                    checkDataCounter: {
                      ...this.state.checkDataCounter,
                      [activeInput]: 1,
                    },
                  }))
                } else if (
                  this.state.bulletPoints &&
                  this.state.sequencePoints == false
                ) {
                  formData[activeInput] =
                    existingValue +
                    "\r\n" +
                    `${this.state.space}` +
                    "\u2022" +
                    " "
                  this.setState({ recordAuto: 1, checkBullet: false })
                }
              }
            }
          )
          if (this.state.sequencePoints) {
            let existingValue = formData[activeInput]
            this.setState({ nestedPoint: true, nestedSeq: false }, () => {
              if (
                this.state.bulletPoints &&
                this.state.sequencePoints &&
                this.state.nestedPoint
              ) {
                formData[activeInput] =
                  existingValue +
                  "\r\n" +
                  `${this.state.space}` +
                  "\u2022" +
                  " "
                this.setState({ recordAuto: 1, checkBullet: false })
              }
            })
            this.setState((prevState) => ({
              space: prevState.space + "\u0020\u0020\u0020",
            }))
          }
        }
        break
      // to stop bullet points
      case "Stop point.":
        if (activeInput.includes("minute")) {
          this.setState(
            {
              recordAuto: 1,
              bulletPoints: false,
              newLineOnBullets: true,
            },
            () => {
              let existingValue = formData[activeInput]
              if (
                this.state.newLineOnBullets &&
                this.state.sequencePoints == false
              ) {
                formData[activeInput] = existingValue + "\r\n"
                this.setState({ recordAuto: 1, newLineOnBullets: false }, () =>
                  this._processCommand("")
                )
              }
            }
          )

          if (this.state.sequencePoints) {
            this.setState(
              {
                nestedSeq: true,
                nestedPoint: false,
                newLineOnBullets: false,
                bulletPoints: true,
              },
              () => {
                let existingValue = formData[activeInput]
                if (
                  this.state.bulletPoints &&
                  this.state.sequencePoints &&
                  this.state.nestedSeq
                ) {
                  this.setState(
                    (prevState) => ({
                      sequenceNumber: prevState.sequenceNumber + 1,
                    }),
                    () => {
                      formData[activeInput] =
                        existingValue +
                        "\r\n" +
                        `${this.state.space}` +
                        this.state.sequenceNumber +
                        ". "
                      this.setState({ recordAuto: 1, checkBullet: false })
                    }
                  )
                }
              }
            )
            this.setState((prevState) => ({
              space: prevState.space.slice(0, -3),
            }))
          }
        }
        break
      // to resume sequence
      case "Back to sequence.":
        if (activeInput.includes("minute")) {
          this.setState(
            {
              recordAuto: 1,
              nestedSeq: true,
              nestedPoint: false,
              sequencePoints: true,
            },
            () => {
              let existingValue = formData[activeInput]
              if (
                this.state.sequencePoints &&
                (this.state.nestedSeq || this.state.nestedPoint)
              ) {
                let sequenceNumber = this.state.sequenceNumber
                if (this.state.nestedPoint) {
                  sequenceNumber++
                } else {
                  sequenceNumber += 1
                }
                formData[activeInput] =
                  existingValue +
                  "\r\n" +
                  `${this.state.space}` +
                  sequenceNumber +
                  ". "
                this.setState((prevState) => ({
                  sequenceNumber: sequenceNumber,
                  recordAuto: 1,
                  checkBullet: false,
                }))
              }
            }
          )
          this.setState((prevState) => ({
            space: prevState.space + "\u0020\u0020\u0020",
          }))
        }
        break
      // to start numbered points
      case "Start sequence.":
        if (activeInput.includes("minute")) {
          this.setState(
            {
              recordAuto: 1,
              sequencePoints: true,
              bulletPoints: false,
              nestedSeq: true, // Indicate the start of nested sequence
              nestedPoint: false,
            },
            () => {
              const existingValue = formData[activeInput]
              const timeElapsed = Date.now()
              const today = new Date(timeElapsed)
              if (this.state.checkDataForMinutes[activeInput] == 0) {
                if (
                  this.state.inputHistory[activeInput].length == 0 &&
                  this.state.sequencePoints == true
                ) {
                  this.setState(
                    (prevState) => ({
                      sequenceNumber: prevState.sequenceNumber + 1,
                    }),
                    () => {
                      formData[activeInput] =
                        existingValue +
                        "[Updated on: " +
                        moment().format("MMMM Do YYYY") +
                        "] " +
                        "\r\n" +
                        "\u0020\u0020\u0020" +
                        this.state.sequenceNumber +
                        ". "
                      this.setState({ recordAuto: 1, checkBullet: false })
                    }
                  )
                } else if (
                  this.state.sequencePoints &&
                  this.state.bulletPoints == false
                ) {
                  this.setState(
                    (prevState) => ({
                      sequenceNumber: prevState.sequenceNumber + 1,
                    }),
                    () => {
                      formData[activeInput] =
                        existingValue +
                        "\r\n" +
                        `${this.state.space}` +
                        this.state.sequenceNumber +
                        ". "
                      this.setState({ recordAuto: 1, checkBullet: false })
                    }
                  )
                }
              } else if (this.state.checkDataForMinutes[activeInput] == 1) {
                if (
                  this.state.checkDataCounter[activeInput] == 0 &&
                  this.state.sequencePoints == true
                ) {
                  this.setState(
                    (prevState) => ({
                      sequenceNumber: prevState.sequenceNumber + 1,
                    }),
                    () => {
                      formData[activeInput] =
                        existingValue +
                        "\r\n" +
                        "[Updated on: " +
                        moment().format("MMMM Do YYYY") +
                        "] " +
                        "\r\n" +
                        "\u0020\u0020\u0020" +
                        this.state.sequenceNumber +
                        ". "
                      this.setState({ recordAuto: 1, checkBullet: false })
                      this.setState((prevState) => ({
                        checkDataCounter: {
                          ...this.state.checkDataCounter,
                          [activeInput]: 1,
                        },
                      }))
                    }
                  )
                } else if (
                  this.state.sequencePoints &&
                  this.state.bulletPoints == false
                ) {
                  this.setState(
                    (prevState) => ({
                      sequenceNumber: prevState.sequenceNumber + 1,
                    }),
                    () => {
                      formData[activeInput] =
                        existingValue +
                        "\r\n" +
                        `${this.state.space}` +
                        this.state.sequenceNumber +
                        ". "
                      this.setState({ recordAuto: 1, checkBullet: false })
                    }
                  )
                }
              }
            }
          )
          if (this.state.bulletPoints) {
            this.setState({ nestedSeq: true, nestedPoint: false }, () => {
              let existingValue = formData[activeInput]
              if (
                this.state.bulletPoints &&
                this.state.sequencePoints &&
                this.state.nestedSeq
              ) {
                this.setState(
                  (prevState) => ({
                    sequenceNumber: prevState.sequenceNumber + 1,
                  }),
                  () => {
                    formData[activeInput] =
                      existingValue +
                      "\r\n" +
                      `${this.state.space}` +
                      this.state.sequenceNumber +
                      ". "
                    this.setState({ recordAuto: 1, checkBullet: false })
                  }
                )
              }
            })
            this.setState((prevState) => ({
              space: prevState.space + "\u0020\u0020\u0020",
            }))
          }
        }
        break
      // to stop numbered points
      case "Stop sequence.":
        if (activeInput.includes("minute")) {
          this.setState(
            {
              recordAuto: 1,
              sequencePoints: false,
              newLineOnSequence: true,
              sequenceNumber: 0,
              nestedSeq: false,
            },
            () => {
              let existingValue = formData[activeInput]
              if (
                this.state.newLineOnSequence &&
                this.state.bulletPoints == false
              ) {
                this.setState(
                  (prevState) => ({
                    formData: {
                      ...formData,
                      [activeInput]: existingValue + "\r\n",
                    },
                    recordAuto: 1,
                    newLineOnSequence: false,
                  }),
                  () => {
                    this._processCommand("")
                  }
                )
              }
            }
          )

          if (this.state.bulletPoints) {
            this.setState(
              {
                nestedPoint: true,
                nestedSeq: false,
                newLineOnSequence: false,
                sequencePoints: true,
              },
              () => {
                let existingValue = formData[activeInput]

                if (
                  this.state.bulletPoints &&
                  this.state.sequencePoints &&
                  this.state.nestedPoint
                ) {
                  formData[activeInput] =
                    existingValue +
                    "\r\n" +
                    `${this.state.space}` +
                    "\u2022" +
                    " "
                  this.setState({ recordAuto: 1, checkBullet: false })
                }
              }
            )
            this.setState((prevState) => ({
              space: prevState.space.slice(0, -3),
            }))
          }
        }
        break
      // to add new line in minute section
      case "Add new line.":
      case "Add line.":
      case "Next line.":
      case "Add paragraph.":
      case "New paragraph.":
        if (activeInput.includes("minute")) {
          this.setState(
            {
              recordAuto: 1,
              bulletPoints: false,
              sequencePoints: false,
              newLineOnBullets: true,
            },
            () => {
              let existingValue = formData[activeInput]
              if (
                this.state.newLineOnBullets &&
                this.state.sequencePoints == false
              ) {
                this.setState({ recordAuto: 1, newLineOnBullets: false })
                formData[activeInput] = existingValue + "\r\n"
              }
            }
          )
        }
        break
      //scroll to top
      case "Scroll to top.":
      case "Go to top.":
        window.scrollTo(0, 0)

        break
      // to add new row in minute section
      case "Add new minute.":
      case "Add minute.":
      case "New minute.":
        if (
          activeInput.includes("minute") ||
          activeInput.includes("owner") ||
          activeInput.includes("date") ||
          activeInput.includes("status")
        ) {
          this.state.refjson &&
            this.state.refjson.minutesFields.forEach((each) =>
              each.minutes.forEach(async (subEach) => {
                if (this.state.rowNum == subEach.ref) {
                  let lastRef = each.minutes[each.minutes.length - 1].ref
                  let firstNum = lastRef.charAt(lastRef.length - 4)
                  let lastNum = Number(lastRef.charAt(lastRef.length - 2))
                  each.minutes.push({
                    ref: `${firstNum}.${lastNum + 1}.`,
                    minute: `minute${firstNum}.${lastNum + 1}.`,
                    owner: `owner${firstNum}.${lastNum + 1}.`,
                    status: `status${firstNum}.${lastNum + 1}.`,
                    date: `date${firstNum}.${lastNum + 1}.`,
                    minuteLineCount: `minuteLineCount${firstNum}.${lastNum +
                      1}.`,
                    minuteLineHeight: `minuteLineHeight${firstNum}.${lastNum +
                      1}.`,
                    triggers: [
                      `Date ${firstNum}.${lastNum + 1}.`,
                      `Owner ${firstNum}.${lastNum + 1}.`,
                      `Status ${firstNum}.${lastNum + 1}.`,
                      `Minute ${firstNum}.${lastNum + 1}.`,
                      `Ona ${firstNum}.${lastNum + 1}.`,
                      `${firstNum}.${lastNum + 1}.`,
                    ],
                  })

                  this.state.refjson &&
                    this.state.refjson.commands.triggers.push(
                      `date ${firstNum}.${lastNum + 1}`,
                      `owner ${firstNum}.${lastNum + 1}`,
                      `status ${firstNum}.${lastNum + 1}`,
                      `minute ${firstNum}.${lastNum + 1}`,
                      `ona ${firstNum}.${lastNum + 1}`,
                      `${firstNum}.${lastNum + 1}`
                    )

                  // update json file in server
                  this._updateRefJSONFile(this.state.refjson)

                  let filteredArray = each.minutes.filter(
                    (subEach) => subEach.ref == `${firstNum}.${lastNum + 1}.`
                  )

                  // initializing input fields of minute section
                  let inputs = {}
                  let input_History = {}

                  for (let i = each.minutes.length - 1; i >= 0; i--) {
                    let ref = each.minutes[i].ref
                    let firstNum2 = ref.charAt(ref.length - 4)
                    let lastNum2 = Number(ref.charAt(ref.length - 2))

                    if (
                      ref ==
                      `${String(this.state.rowNum).charAt(
                        this.state.rowNum.length - 4
                      )}.${Number(
                        Number(
                          this.state.rowNum.charAt(this.state.rowNum.length - 2)
                        ) + 1
                      )}.`
                    ) {
                      inputs[`minute${firstNum2}.${lastNum2}.`] = ""
                      inputs[`owner${firstNum2}.${lastNum2}.`] = ""
                      inputs[`date${firstNum2}.${lastNum2}.`] = ""
                      inputs[`status${firstNum2}.${lastNum2}.`] = ""
                      inputs[`minuteLineCount${firstNum2}.${lastNum2}.`] = []
                      inputs[`minuteLineHeight${firstNum2}.${lastNum2}.`] = 48
                      input_History[`minute${firstNum2}.${lastNum2}.`] = ""
                      input_History[`owner${firstNum2}.${lastNum2}.`] = ""
                      input_History[`date${firstNum2}.${lastNum2}.`] = ""
                      input_History[`status${firstNum2}.${lastNum2}.`] = ""
                      this.state.minuteLineCount[
                        `minuteLineCount${firstNum2}.${lastNum2}.`
                      ] = []
                      this.setState((prev) => ({
                        activeInput: `minute${firstNum2}.${lastNum2}.`,
                        checkDataForMinutes: {
                          ...this.state.checkDataForMinutes,
                          [`minute${firstNum2}.${lastNum2}.`]: 0,
                        },
                      }))
                    } else if (
                      ref !==
                      `${String(this.state.rowNum).charAt(
                        this.state.rowNum.length - 4
                      )}.${Number(
                        Number(
                          this.state.rowNum.charAt(this.state.rowNum.length - 2)
                        )
                      )}.`
                    ) {
                      inputs[
                        `minute${firstNum2}.${lastNum2}.`
                      ] = this.state.formData[
                        `minute${firstNum2}.${lastNum2 - 1}.`
                      ]
                      inputs[
                        `owner${firstNum2}.${lastNum2}.`
                      ] = this.state.formData[
                        `owner${firstNum2}.${lastNum2 - 1}.`
                      ]
                      inputs[
                        `date${firstNum2}.${lastNum2}.`
                      ] = this.state.formData[
                        `date${firstNum2}.${lastNum2 - 1}.`
                      ]
                      inputs[
                        `status${firstNum2}.${lastNum2}.`
                      ] = this.state.formData[
                        `status${firstNum2}.${lastNum2 - 1}.`
                      ]
                      inputs[
                        `minuteLineCount${firstNum2}.${lastNum2}.`
                      ] = this.state.formData[
                        `minuteLineCount${firstNum2}.${lastNum2 - 1}.`
                      ]
                      if (i == each.minutes.length - 1) {
                        this.state.minuteLineCount[
                          `minuteLineCount${firstNum2}.${lastNum2}.`
                        ] = this.state.formData[
                          `minuteLineCount${firstNum2}.${lastNum2 - 1}.`
                        ]
                      }

                      inputs[
                        `minuteLineHeight${firstNum2}.${lastNum2}.`
                      ] = this.state.formData[
                        `minuteLineHeight${firstNum2}.${lastNum2 - 1}.`
                      ]
                      input_History[
                        `minute${firstNum2}.${lastNum2}.`
                      ] = this.state.inputHistory[
                        `minute${firstNum2}.${lastNum2 - 1}.`
                      ]
                      input_History[
                        `owner${firstNum2}.${lastNum2}.`
                      ] = this.state.inputHistory[
                        `owner${firstNum2}.${lastNum2 - 1}.`
                      ]
                      input_History[
                        `date${firstNum2}.${lastNum2}.`
                      ] = this.state.inputHistory[
                        `date${firstNum2}.${lastNum2 - 1}.`
                      ]
                      input_History[
                        `status${firstNum2}.${lastNum2}.`
                      ] = this.state.inputHistory[
                        `status${firstNum2}.${lastNum2 - 1}.`
                      ]
                    } else if (
                      ref ==
                      `${String(this.state.rowNum).charAt(
                        this.state.rowNum.length - 4
                      )}.${Number(
                        Number(
                          this.state.rowNum.charAt(this.state.rowNum.length - 2)
                        )
                      )}.`
                    ) {
                      break
                    }
                  }

                  // checking if there is any data in minute field or not
                  // it is used for printing [Updated on : timestamp] in minute fields
                  // we are setting checkDataForMinutes : 1, if it has data and after updating minute field we are setting  checkDataCounter : 1 for that input field, so that we can track and not to print timestamp again in that session.

                  this.setState((prevState) => ({
                    recordAuto: 1,
                    formData: {
                      ...formData,
                      ...inputs,
                    },
                    inputHistory: {
                      ...inputHistory,
                      ...input_History,
                    },
                  }))

                  // inputs[filteredArray[0].minute] = ""
                  // inputs[filteredArray[0].owner] = ""
                  // inputs[filteredArray[0].date] = ""
                  // inputs[filteredArray[0].status] = ""
                  // inputs[filteredArray[0].minuteLineCount] = []
                  // this.state.minuteLineCount[
                  //   filteredArray[0].minuteLineCount
                  // ] = []
                  // inputs[filteredArray[0].minuteLineHeight] = 48
                  // input_History[filteredArray[0].minute] = ""

                  // input_History[filteredArray[0].owner] = ""
                  // input_History[filteredArray[0].date] = ""
                  // input_History[filteredArray[0].status] = ""

                  // // checking if there is any data in minute field or not
                  // // it is used for printing [Updated on : timestamp] in minute fields
                  // // we are setting checkDataForMinutes : 1, if it has data and after updating minute field we are setting  checkDataCounter : 1 for that input field, so that we can track and not to print timestamp again in that session.

                  // this.setState((prevState) => ({
                  //   checkDataForMinutes: {
                  //     ...this.state.checkDataForMinutes,
                  //     [filteredArray[0].minute]: 0,
                  //   },
                  //   formData: {
                  //     ...formData,
                  //     ...inputs,
                  //   },
                  //   inputHistory: {
                  //     ...inputHistory,
                  //     ...input_History,
                  //   },
                  // }))
                  minuteRefs.push((filteredArray[0] = React.createRef()))
                  ownerRefs.push((filteredArray[0] = React.createRef()))
                  dateRefs.push((filteredArray[0] = React.createRef()))
                  statusRefs.push((filteredArray[0] = React.createRef()))
                  const index = this._goToNextInput(activeInput, minuteRefs)

                  minuteRefs[index + 1].current.focus()
                  this.setState({
                    minuteRefs,
                    ownerRefs,
                    dateRefs,
                    statusRefs,
                  })
                }
              })
            )
        }
        break

      // to delete current row in minute section
      case "Delete minute.":
      case "Delete current minute.":
      case "Delete row.":
      case "Delete current row.":
        if (
          activeInput.includes("minute") ||
          activeInput.includes("owner") ||
          activeInput.includes("date") ||
          activeInput.includes("status")
        ) {
          this.state.refjson &&
            this.state.refjson.minutesFields.forEach((each) =>
              each.minutes.forEach(async (subEach) => {
                if (activeInput == subEach.minute) {
                  const index = each.minutes.indexOf(subEach)

                  // deleting input fields of minute section from formdata state
                  let inputs = {}
                  let input_History = {}
                  for (let i = index; i <= each.minutes.length - 1; i++) {
                    let ref = each.minutes[i].ref

                    let firstNum2 = ref.charAt(ref.length - 4)
                    let lastNum2 = Number(ref.charAt(ref.length - 2))

                    if (i !== each.minutes.length - 1) {
                      inputs[`minute${firstNum2}.${lastNum2}.`] =
                        formData[`minute${firstNum2}.${lastNum2 + 1}.`]
                      inputs[`owner${firstNum2}.${lastNum2}.`] =
                        formData[`owner${firstNum2}.${lastNum2 + 1}.`]
                      inputs[`date${firstNum2}.${lastNum2}.`] =
                        formData[`date${firstNum2}.${lastNum2 + 1}.`]
                      inputs[`status${firstNum2}.${lastNum2}.`] =
                        formData[`status${firstNum2}.${lastNum2 + 1}.`]
                      inputs[`minuteLineCount${firstNum2}.${lastNum2}.`] =
                        formData[`minuteLineCount${firstNum2}.${lastNum2 + 1}.`]

                      inputs[`minuteLineHeight${firstNum2}.${lastNum2}.`] =
                        formData[
                          `minuteLineHeight${firstNum2}.${lastNum2 + 1}.`
                        ]
                      input_History[`minute${firstNum2}.${lastNum2}.`] =
                        inputHistory[`minute${firstNum2}.${lastNum2 + 1}.`]
                      input_History[`owner${firstNum2}.${lastNum2}.`] =
                        inputHistory[`owner${firstNum2}.${lastNum2 + 1}.`]
                      input_History[`date${firstNum2}.${lastNum2}.`] =
                        inputHistory[`date${firstNum2}.${lastNum2 + 1}.`]
                      input_History[`status${firstNum2}.${lastNum2}.`] =
                        inputHistory[`status${firstNum2}.${lastNum2 + 1}.`]
                    } else {
                      delete formData[`minute${ref}`]
                      delete formData[`owner${ref}`]
                      delete formData[`date${ref}`]
                      delete formData[`status${ref}`]
                      delete formData[`minuteLineCount${ref}`]
                      delete formData[`minuteLineHeight${ref}`]
                      delete this.state.minuteLineCount[`minuteLineCount${ref}`]
                      delete inputHistory[`minute${ref}`]
                      delete inputHistory[`owner${ref}`]
                      delete inputHistory[`status${ref}`]
                      delete inputHistory[`date${ref}`]
                      delete this.state.checkDataForMinutes[`minute${ref}`]
                      if (this.state.checkDataCounter[`minute${ref}`])
                        delete this.state.checkDataCounter[`minute${ref}`]
                      const trigger = ref.substring(0, ref.length - 1)
                      this.state.refjson.commands.triggers.forEach((i) => {
                        if (i == `date ${trigger}`) {
                          let index = this.state.refjson.commands.triggers.indexOf(
                            i
                          )
                          this.state.refjson.commands.triggers.splice(index, 5)
                        }
                      })
                      each.minutes.pop()
                      // update json file in server
                      this._updateRefJSONFile(this.state.refjson)
                      break
                    }
                  }

                  // const rowNum = this.state.rowNum.substring(
                  //   0,
                  //   this.state.rowNum.length - 1
                  // );

                  // this.state.refjson.commands.triggers.forEach((i) => {
                  //   if (i == `date ${rowNum}`) {
                  //     let index = this.state.refjson.commands.triggers.indexOf(
                  //       i
                  //     );

                  //     this.state.refjson.commands.triggers.splice(index, 5);
                  //   }
                  // });

                  // const index = each.minutes.indexOf(subEach);
                  // each.minutes.splice(index, 1);
                  // update json file in server
                  // this._updateRefJSONFile(this.state.refjson);

                  // deleting input fields of minute section from formdata state

                  // delete formData[`minute${rowNum}.`];
                  // delete formData[`owner${rowNum}.`];
                  // delete formData[`date${rowNum}.`];
                  // delete formData[`status${rowNum}.`];
                  // delete formData[`minuteLineCount${rowNum}.`];
                  // delete formData[`minuteLineHeight${rowNum}.`];
                  // delete this.state.minuteLineCount[
                  //   `minuteLineCount${rowNum}.`
                  // ];
                  // delete inputHistory[`minute${rowNum}.`];
                  // delete inputHistory[`owner${rowNum}.`];
                  // delete inputHistory[`status${rowNum}.`];
                  // delete inputHistory[`date${rowNum}.`];

                  // checking if there is any data in minute field or not
                  // it is used for printing [Updated on : timestamp] in minute fields
                  // we are setting checkDataForMinutes : 1, if it has data and after updating minute field we are setting  checkDataCounter : 1 for that input field, so that we can track and not to print timestamp again in that session.

                  // delete this.state.checkDataForMinutes[`minute${rowNum}.`];
                  // if (this.state.checkDataCounter[`minute${rowNum}.`])
                  //   delete this.state.checkDataCounter[`minute${rowNum}.`];
                  this.setState((prevState) => ({
                    checkDataForMinutes: {
                      ...this.state.checkDataForMinutes,
                    },
                    formData: {
                      ...formData,
                      ...inputs,
                    },
                    inputHistory: {
                      ...inputHistory,
                      ...input_History,
                    },
                  }))
                  minuteRefs.pop()
                  ownerRefs.pop()
                  dateRefs.pop()
                  statusRefs.pop()

                  this.setState({
                    minuteRefs,
                    ownerRefs,
                    dateRefs,
                    statusRefs,
                  })
                }
              })
            )
        }
        break
      // to add new section in minutes
      case "Add new section.":
      case "Add section.":
      case "New section.":
        if (
          activeInput.includes("minute") ||
          activeInput.includes("owner") ||
          activeInput.includes("date") ||
          activeInput.includes("status")
        ) {
          if (this.state.refjson) {
            const { minutesFields, commands } = this.state.refjson
            let lastSrNo = Number(minutesFields[minutesFields.length - 1].SrNo)

            function numberToWord(number) {
              let words = [
                "zero",
                "one",
                "two",
                "three",
                "four",
                "five",
                "six",
                "seven",
                "eight",
                "nine",
              ]

              return words[number]
            }

            let word = numberToWord(lastSrNo + 1)
            let convertedWord =
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

            minutesFields.push({
              label: "New Section",
              SrNo: `${lastSrNo + 1}`,
              no: `${lastSrNo + 1}`,
              title: `title${lastSrNo + 1}`,
              triggers: [
                `Title ${word}.`,
                `Edit title ${word}.`,
                `${convertedWord}.`,
                `Title ${lastSrNo + 1}.`,
                `${lastSrNo + 1}.`,
                `Edit Title ${lastSrNo + 1}.`,
              ],

              minutes: [
                {
                  ref: `${lastSrNo + 1}.1.`,
                  minute: `minute${lastSrNo + 1}.1.`,
                  owner: `owner${lastSrNo + 1}.1.`,
                  date: `date${lastSrNo + 1}.1.`,
                  status: `status${lastSrNo + 1}.1.`,
                  minuteLineCount: `minuteLineCount${lastSrNo + 1}.1.`,
                  minuteLineHeight: `minuteLineHeight${lastSrNo + 1}.1.`,
                  triggers: [
                    `Date ${lastSrNo + 1}.1.`,
                    `Owner ${lastSrNo + 1}.1.`,
                    `Status ${lastSrNo + 1}.1.`,
                    `Minute ${lastSrNo + 1}.1.`,
                    `Ona ${lastSrNo + 1}.1.`,
                    `${lastSrNo + 1}.1.`,
                  ],
                },
                {
                  ref: `${lastSrNo + 1}.2.`,
                  minute: `minute${lastSrNo + 1}.2.`,
                  owner: `owner${lastSrNo + 1}.2.`,
                  date: `date${lastSrNo + 1}.2.`,
                  status: `status${lastSrNo + 1}.2.`,
                  minuteLineCount: `minuteLineCount${lastSrNo + 1}.2.`,
                  minuteLineHeight: `minuteLineHeight${lastSrNo + 1}.2.`,
                  triggers: [
                    `Date ${lastSrNo + 1}.2.`,
                    `Owner ${lastSrNo + 1}.2.`,
                    `Status ${lastSrNo + 1}.2.`,
                    `Minute ${lastSrNo + 1}.2.`,
                    `Ona ${lastSrNo + 1}.2.`,
                    `${lastSrNo + 1}.2.`,
                  ],
                },
              ],
            })

            commands.triggers.push(
              `date ${lastSrNo + 1}.1`,
              `owner ${lastSrNo + 1}.1`,
              `minute ${lastSrNo + 1}.1`,
              `status ${lastSrNo + 1}.1`,
              `ona ${lastSrNo + 1}.1`,
              `${lastSrNo + 1}.1`,
              `date ${lastSrNo + 1}.2`,
              `owner ${lastSrNo + 1}.2`,
              `status ${lastSrNo + 1}.2`,
              `minute ${lastSrNo + 1}.2`,
              `ona ${lastSrNo + 1}.2`,
              `${lastSrNo + 1}.2`
            )

            //store label

            labels.push("New section")
            nos.push(lastSrNo + 1)

            // update json file in server
            this._updateRefJSONFile(this.state.refjson)

            let filteredArray = minutesFields.filter(
              (each) => each.SrNo == `${lastSrNo + 1}`
            )

            // initializing input fields of minute section
            let inputs = {}
            let input_History = {}

            filteredArray[0].minutes.forEach((subEach) => {
              inputs[subEach.minute] = ""
              inputs[subEach.owner] = ""
              inputs[subEach.date] = ""
              inputs[subEach.status] = ""
              inputs[subEach.minuteLineCount] = []
              this.state.minuteLineCount[subEach.minuteLineCount] = []
              inputs[subEach.minuteLineHeight] = 48
              inputs[filteredArray[0].title] = "New Section"

              input_History[subEach.minute] = ""
              input_History[subEach.owner] = ""
              input_History[subEach.date] = ""
              input_History[subEach.status] = ""
              input_History[filteredArray[0].title] = ""

              // checking if there is any data in minute field or not
              // it is used for printing [Updated on : timestamp] in minute fields
              // we are setting checkDataForMinutes : 1, if it has data and after updating minute field we are setting  checkDataCounter : 1 for that input field, so that we can track and not to print timestamp again in that session.

              this.setState((prevState) => ({
                checkDataForMinutes: {
                  ...this.state.checkDataForMinutes,
                  [subEach.minute]: 0,
                },
                formData: {
                  ...formData,
                  ...inputs,
                },
                inputHistory: {
                  ...inputHistory,
                  ...input_History,
                },
              }))
            })

            filteredArray.map((each) => {
              labelRefs.push((each.label = React.createRef()))
              labelRef.push((each.no = React.createRef()))
              each.minutes.map((subEach) => {
                minuteRefs.push((subEach = React.createRef()))
                ownerRefs.push((subEach = React.createRef()))
                dateRefs.push((subEach = React.createRef()))
                statusRefs.push((subEach = React.createRef()))
              })
            })

            this.setState({
              minuteRefs,
              ownerRefs,
              statusRefs,
              dateRefs,
              labelRefs,
              labelRef,
            })
          }
        }
        break
      // to delete current row in minute section
      case "Delete section.":
      case "Delete current section.":
        if (
          activeInput.includes("minute") ||
          activeInput.includes("owner") ||
          activeInput.includes("date") ||
          activeInput.includes("status")
        ) {
          if (this.state.refjson) {
            const { commands, minutesFields } = this.state.refjson
            const rowNum = this.state.rowNum.substring(
              0,
              this.state.rowNum.length - 1
            )
            // const currentIndex = Number(this.state.rowNum.charAt(0)) - 1
            const currentIndex = minutesFields.findIndex(
              (each) => each.SrNo == Number(this.state.rowNum.charAt(0))
            )

            let lengthOfSectioRows = minutesFields[currentIndex].minutes.length

            commands.triggers.forEach((i) => {
              if (i == `date ${currentIndex}.1`) {
                let index = this.state.refjson.commands.triggers.indexOf(i)
                this.state.refjson.commands.triggers.splice(
                  index,
                  5 * lengthOfSectioRows
                )
              }
            })

            minutesFields.splice(currentIndex, 1)
            // update json file in server
            this._updateRefJSONFile(this.state.refjson)

            // deleting input fields of minute section from formdata state
            for (let i = 0; i < currentIndex; i++) {
              delete formData[`minute${currentIndex}.${i + 1}`]
              delete formData[`owner${currentIndex}.${i + 1}`]
              delete formData[`date${currentIndex}.${i + 1}`]
              delete formData[`status${currentIndex}.${i + 1}`]
              delete formData[`minuteLineCount${currentIndex}.${i + 1}`]
              delete formData[`minuteLineHeight${currentIndex}.${i + 1}`]
              delete this.state.minuteLineCount[
                `minuteLineCount${currentIndex}.${i + 1}`
              ]
              delete inputHistory[`minute${currentIndex}.${i + 1}`]
              delete inputHistory[`owner${currentIndex}.${i + 1}`]
              delete inputHistory[`status${currentIndex}.${i + 1}`]
              delete inputHistory[`date${currentIndex}.${i + 1}`]

              // checking if there is any data in minute field or not
              // it is used for printing [Updated on : timestamp] in minute fields
              // we are setting checkDataForMinutes : 1, if it has data and after updating minute field we are setting  checkDataCounter : 1 for that input field, so that we can track and not to print timestamp again in that session.

              delete this.state.checkDataForMinutes[
                `minute${currentIndex}.${i + 1}`
              ]
              if (this.state.checkDataCounter[`minute${currentIndex}.${i + 1}`])
                delete this.state.checkDataCounter[
                  `minute${currentIndex}.${i + 1}`
                ]

              minuteRefs.pop()
              ownerRefs.pop()
              dateRefs.pop()
              statusRefs.pop()
            }
            delete formData[`title${currentIndex}.`]
            delete inputHistory[`title${currentIndex}.`]

            this.setState((prevState) => ({
              checkDataForMinutes: {
                ...this.state.checkDataForMinutes,
              },
              formData: {
                ...formData,
              },
              inputHistory: {
                ...inputHistory,
              },
            }))

            // minuteRefs.pop();
            // ownerRefs.pop();
            // dateRefs.pop();
            labelRef.pop()
            labelRefs.pop()

            this.setState({
              minuteRefs,
              ownerRefs,
              dateRefs,
              labelRef,
              labelRefs,
              statusRefs,
            })
          }
        }
        break
      // process text which doesnt match with any other custom commands
      default: {
        // focusing input based on custom command
        let input
        if (this.state.selectedProject == 1) {
          input =
            this.state.refjson &&
            this.state.refjson.fields.find((each) =>
              each.triggers.includes(recordedString)
            )
        } else if (this.state.selectedProject == 2) {
          input =
            this.state.refjson &&
            this.state.refjson.nextProjectFields.find((each) =>
              each.triggers.includes(recordedString)
            )
        } else if (this.state.selectedProject == 4) {
          let titleInput =
            this.state.refjson &&
            this.state.refjson.minutesFields.find((each) =>
              each.triggers.includes(recordedString)
            )
          this.state.refjson &&
            this.state.refjson.minutesFields.map((each, index) => {
              let minInput = each.minutes.find((subEach, index) =>
                subEach.triggers.includes(recordedString)
              )

              if (minInput) {
                input = minInput
                return input
              } else if (titleInput) {
                input = titleInput
                return input
              }
            })
        }
        if (input) {
          // if any active input is there then fill it with value
          // set input active
          // eslint-disable-next-line react/destructuring-assignment
          if (this.state.selectedProject == 1) {
            this._findRefInput(projectRefs, input.name).current.focus()
            this.setState({ activeInput: input.name })
            // if (type?.type == 2) {
            //   formData[activeInput] = ""
            //   this.setState({ ...formData })
            // }
            this.setState({ recordAuto: 1 })
          } else if (this.state.selectedProject == 2) {
            this._findRefInput(nextProjectRefs, input.name).current.focus()
            this.setState({ activeInput: input.name })
            // if (type?.type == 2) {
            //   formData[activeInput] = ""
            //   this.setState({ ...formData })
            // }
            this.setState({ recordAuto: 1 })
          } else if (this.state.selectedProject == 4) {
            if (
              recordedString.includes("Minute") ||
              recordedString == input.ref
            ) {
              this.setState({
                activeInput: input.minute,
                bulletPoints: false,
                nestedPoint: false,
                sequencePoints: false,
                nestedSeq: false,
                sequenceNumber: 0,
              })

              this._findRefMinuteInput(minuteRefs, input.minute).current.focus()
              const index = this._goToNextInput(input.minute, minuteRefs)

              // const srno = input.minute.charAt(input.minute.length - 4);

              minuteRefs[index].current.scrollIntoView({
                block: "center",
                behavior: "smooth",
              })

              this.setState({ recordAuto: 1 })
            } else if (
              recordedString.includes("Owner") ||
              recordedString.includes("Ona")
            ) {
              this.setState({ activeInput: input.owner })
              this._findRefMinuteInput(ownerRefs, input.owner).current.focus()
              const srno = input.owner.charAt(input.owner.length - 4)
              this.state.labelRef[srno - 1].current.scrollIntoView()
              this.setState({ recordAuto: 1 })
            } else if (recordedString.includes("Date")) {
              this.setState({ activeInput: input.date })
              this._findRefMinuteInput(dateRefs, input.date).current.focus()
              const srno = input.date.charAt(input.date.length - 4)
              this.state.labelRef[srno - 1].current.scrollIntoView()
              this.setState({ recordAuto: 1 })
            } else if (recordedString.includes("Status")) {
              this.setState({ activeInput: input.status })
              this._findRefMinuteInput(statusRefs, input.status).current.focus()
              const srno = input.status.charAt(input.status.length - 4)
              this.state.labelRef[srno - 1].current.scrollIntoView()
              this.setState({ recordAuto: 1 })
            } else if (input.triggers.includes(recordedString)) {
              this.setState({
                labelButton: false,
              })
              this._findRefMinuteInput(
                this.state.labelRefs,
                input.title
              ).current.focus()

              let index = this._findIndexOfInput(
                this.state.labelRefs,
                input.title
              )

              this.setState({
                activeInput: input.title,
              })

              this.state.labelRefs[index].current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              })
            }
          } else {
            this.setState({ activeInput: input.name })
          }
        } else if (activeInput) {
          let existingValue = formData[activeInput]

          if (
            this.state.selectedProject == 1 ||
            this.state.selectedProject == 2
          ) {
            if (type?.type == 2) {
              formData[activeInput] = ""
              existingValue = ""
              this.setState({ ...formData })
            }
          }
          if (activeInput === "email") {
            // eslint-disable-next-line no-param-reassign
            recordedString = recordedString.toLowerCase().replace(/\s/g, "")
          }

          if (activeInput.includes("minute")) {
            const rowNum = activeInput && activeInput.slice(-4)
            this.setState({ rowNum: rowNum })
            const timeElapsed = Date.now()
            const today = new Date(timeElapsed)

            const el = this._findRefMinuteInput(minuteRefs, activeInput)
            const index = this._goToNextInput(activeInput, minuteRefs)

            if (el.current.clientHeight > window.innerHeight / 1.9) {
              minuteRefs[index + 1].current.scrollIntoView({
                block: "center",
                behavior: "smooth",
              })
            }

            if (this.state.checkDataForMinutes[activeInput] == 0) {
              if (
                this.state.inputHistory[activeInput].length == 0 &&
                this.state.bulletPoints == false &&
                this.state.sequencePoints == false
              ) {
                recordedString =
                  // type.type === "2"
                  // ?
                  "[Updated on: " +
                  moment().format("MMMM Do YYYY") +
                  "] " +
                  "\r\n" +
                  recordedString
                // : recordedString
                this.setState({ recordAuto: 1 })
              } else if (
                this.state.bulletPoints &&
                this.state.sequencePoints == false
              ) {
                if (this.state.isRecognizing) {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      "\u2022" +
                      " " +
                      recordedString
                  } else {
                    recordedString = recordedString
                  }
                } else {
                  this.setState({ recordAuto: 1 })
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      "\u2022" +
                      " " +
                      recordedString
                  } else {
                    recordedString = recordedString
                    this.setState({ checkBullet: true })
                  }
                }
              } else if (
                this.state.bulletPoints &&
                this.state.sequencePoints &&
                this.state.nestedPoint
              ) {
                if (this.state.isRecognizing) {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      "\u2022" +
                      " " +
                      recordedString
                  } else {
                    recordedString = recordedString
                  }
                } else {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      "\u2022" +
                      " " +
                      recordedString
                  } else {
                    recordedString = recordedString
                    this.setState({ checkBullet: true })
                  }
                  this.setState({ recordAuto: 1 })
                }
              } else if (
                this.state.bulletPoints &&
                this.state.sequencePoints &&
                this.state.nestedSeq
              ) {
                if (this.state.isRecognizing) {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      this.state.sequenceNumber +
                      ". " +
                      recordedString
                  } else {
                    recordedString = recordedString
                  }
                } else {
                  if (this.state.checkBullet) {
                    this.setState((prevState) => ({
                      sequenceNumber: prevState.sequenceNumber + 1,
                    }))
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      this.state.sequenceNumber +
                      ". " +
                      recordedString
                  } else {
                    recordedString = recordedString
                    this.setState({ checkBullet: true })
                  }
                  this.setState({ recordAuto: 1 })
                }
              } else if (
                this.state.sequencePoints &&
                this.state.bulletPoints == false
              ) {
                if (this.state.isRecognizing) {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      this.state.sequenceNumber +
                      ". " +
                      recordedString
                  } else {
                    recordedString = recordedString
                  }
                } else {
                  this.setState({ recordAuto: 1 })
                  if (this.state.checkBullet) {
                    this.setState((prevState) => ({
                      sequenceNumber: prevState.sequenceNumber + 1,
                    }))
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      this.state.sequenceNumber +
                      ". " +
                      recordedString
                  } else {
                    recordedString = recordedString
                    this.setState({ checkBullet: true })
                  }
                }
              }
              // else if (
              //   this.state.newLineOnBullets &&
              //   this.state.sequencePoints == false
              // ) {
              //   if (this.state.isRecognizing) {
              //     recordedString = "\r\n" + recordedString;
              //   } else {
              //     this.setState({ recordAuto: 1, newLineOnBullets: false });
              //     recordedString = "\r\n" + recordedString;
              //   }
              // }
              else if (
                this.state.newLineOnSequence &&
                this.state.bulletPoints == false
              ) {
                if (this.state.isRecognizing) {
                  recordedString = "\r\n" + recordedString
                } else {
                  this.setState({ recordAuto: 1, newLineOnSequence: false })
                  recordedString = "\r\n" + recordedString
                }
              } else if (
                this.state.inputHistory[activeInput].length !== 0 &&
                this.state.bulletPoints == false &&
                this.state.sequencePoints == false
              ) {
                this.setState({ recordAuto: 1 })
              }
            } else if (this.state.checkDataForMinutes[activeInput] == 1) {
              if (
                // this.state.inputHistory[activeInput].length == 0 &&
                this.state.checkDataCounter[activeInput] == 0 &&
                this.state.bulletPoints == false &&
                this.state.sequencePoints == false
              ) {
                if (this.state.isRecognizing) {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      "[Updated on: " +
                      moment().format("MMMM Do YYYY") +
                      "] " +
                      "\r\n" +
                      recordedString
                  } else {
                    recordedString = recordedString
                  }
                } else {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      "[Updated on: " +
                      moment().format("MMMM Do YYYY") +
                      "] " +
                      "\r\n" +
                      recordedString
                  } else {
                    recordedString = recordedString
                    this.setState({ checkBullet: true })
                  }
                  this.setState({ recordAuto: 1 })
                  this.setState((prevState) => ({
                    checkDataCounter: {
                      ...this.state.checkDataCounter,
                      [activeInput]: 1,
                    },
                  }))
                }
              }
              // else if (
              //   this.state.checkDataCounter[activeInput] == 0 &&
              //   this.state.bulletPoints == true
              // ) {
              //   if (this.state.isRecognizing) {
              //     recordedString =
              //       "\r\n" +
              //       "[Updated on: " +
              //       moment().format("MMMM Do YYYY") +
              //       "] " +
              //       "\r\n" +
              //       "\u0020\u0020\u0020" +
              //       "\u2022" +
              //       " " +
              //       recordedString;
              //   } else {
              //     recordedString =
              //       "\r\n" +
              //       "[Updated on: " +
              //       moment().format("MMMM Do YYYY") +
              //       "] " +
              //       "\r\n" +
              //       "\u0020\u0020\u0020" +
              //       "\u2022" +
              //       " " +
              //       recordedString;
              //     this.setState({ recordAuto: 1 });
              //     this.setState((prevState) => ({
              //       checkDataCounter: {
              //         ...this.state.checkDataCounter,
              //         [activeInput]: 1,
              //       },
              //     }));
              //   }
              // }
              // else if (
              //   this.state.checkDataCounter[activeInput] == 0 &&
              //   this.state.sequencePoints == true
              // ) {
              //   if (this.state.isRecognizing) {
              //     recordedString =
              //       "\r\n" +
              //       "[Updated on: " +
              //       moment().format("MMMM Do YYYY") +
              //       "] " +
              //       "\r\n" +
              //       "\u0020\u0020\u0020" +
              //       this.state.sequenceNumber +
              //       ". " +
              //       recordedString;
              //   } else {
              //     this.setState((prevState) => ({
              //       sequenceNumber: prevState.sequenceNumber + 1,
              //     }));
              //     recordedString =
              //       "\r\n" +
              //       "[Updated on: " +
              //       moment().format("MMMM Do YYYY") +
              //       "] " +
              //       "\r\n" +
              //       "\u0020\u0020\u0020" +
              //       this.state.sequenceNumber +
              //       ". " +
              //       recordedString;
              //     this.setState({ recordAuto: 1 });
              //     this.setState((prevState) => ({
              //       checkDataCounter: {
              //         ...this.state.checkDataCounter,
              //         [activeInput]: 1,
              //       },
              //     }));
              //   }
              // }
              else if (
                this.state.bulletPoints &&
                this.state.sequencePoints == false
              ) {
                if (this.state.isRecognizing) {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      "\u2022" +
                      " " +
                      recordedString
                  } else {
                    recordedString = recordedString
                  }
                } else {
                  this.setState({ recordAuto: 1 })
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      "\u2022" +
                      " " +
                      recordedString
                  } else {
                    recordedString = recordedString
                    this.setState({ checkBullet: true })
                  }
                }
              } else if (
                this.state.bulletPoints &&
                this.state.sequencePoints &&
                this.state.nestedPoint
              ) {
                if (this.state.isRecognizing) {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      "\u2022" +
                      " " +
                      recordedString
                  } else {
                    recordedString = recordedString
                  }
                } else {
                  this.setState({ recordAuto: 1 })
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      "\u2022" +
                      " " +
                      recordedString
                  } else {
                    recordedString = recordedString
                    this.setState({ checkBullet: true })
                  }
                }
              } else if (
                this.state.bulletPoints &&
                this.state.sequencePoints &&
                this.state.nestedSeq
              ) {
                if (this.state.isRecognizing) {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      this.state.sequenceNumber +
                      ". " +
                      recordedString
                  } else {
                    recordedString = recordedString
                  }
                } else {
                  this.setState({ recordAuto: 1 })
                  this.setState((prevState) => ({
                    sequenceNumber: prevState.sequenceNumber + 1,
                  }))
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      this.state.sequenceNumber +
                      ". " +
                      recordedString
                  } else {
                    recordedString = recordedString
                    this.setState({ checkBullet: true })
                  }
                }
              } else if (
                this.state.sequencePoints &&
                this.state.bulletPoints == false
              ) {
                if (this.state.isRecognizing) {
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      this.state.sequenceNumber +
                      ". " +
                      recordedString
                  } else {
                    recordedString = recordedString
                  }
                } else {
                  this.setState({ recordAuto: 1 })
                  this.setState((prevState) => ({
                    sequenceNumber: prevState.sequenceNumber + 1,
                  }))
                  if (this.state.checkBullet) {
                    recordedString =
                      "\r\n" +
                      `${this.state.space}` +
                      this.state.sequenceNumber +
                      ". " +
                      recordedString
                  } else {
                    recordedString = recordedString
                    this.setState({ checkBullet: true })
                  }
                }
              }
              // else if (
              //   this.state.newLineOnBullets &&
              //   this.state.sequencePoints == false
              // ) {
              //   if (this.state.isRecognizing) {
              //     recordedString = "\r\n" + recordedString;
              //   } else {
              //     this.setState({ recordAuto: 1, newLineOnBullets: false });
              //     recordedString = "\r\n" + recordedString;
              //   }
              // }
              // else if (
              //   this.state.newLineOnSequence &&
              //   this.state.bulletPoints == false
              // ) {
              //   if (this.state.isRecognizing) {
              //     recordedString = "\r\n" + recordedString;
              //   } else {
              //     this.setState({ recordAuto: 1, newLineOnSequence: false });
              //     recordedString = "\r\n" + recordedString;
              //   }
              // }
              else if (
                this.state.checkDataCounter[activeInput] == 1 &&
                this.state.bulletPoints == false &&
                this.state.sequencePoints == false
              ) {
                this.setState({ recordAuto: 1 })
              }
            }
          }

          if (activeInput.includes("owner")) {
            if (this.state.inputHistory[activeInput].length == 0) {
              recordedString = "\r\n" + recordedString
            } else {
              const { rowNum } = this.state
              let str = "\r\n"
              if (formData[`minuteLineCount${rowNum}`].length == 2) {
                recordedString = `${str.repeat(
                  formData[`minuteLineCount${rowNum}`][0]
                )}${recordedString}`
              } else if (formData[`minuteLineCount${rowNum}`].length > 2) {
                recordedString = `${str.repeat(
                  formData[`minuteLineCount${rowNum}`][
                    formData[`minuteLineCount${rowNum}`].length - 2
                  ] -
                    formData[`minuteLineCount${rowNum}`][
                      formData[`minuteLineCount${rowNum}`].length - 3
                    ]
                )}${recordedString}`
              }
            }
          }
          if (activeInput.includes("status")) {
            if (this.state.inputHistory[activeInput].length == 0) {
              recordedString = "\r\n" + recordedString
            } else {
              const { rowNum } = this.state
              let str = "\r\n"
              if (formData[`minuteLineCount${rowNum}`].length == 2) {
                recordedString = `${str.repeat(
                  formData[`minuteLineCount${rowNum}`][0]
                )}${recordedString}`
              } else if (formData[`minuteLineCount${rowNum}`].length > 2) {
                recordedString = `${str.repeat(
                  formData[`minuteLineCount${rowNum}`][
                    formData[`minuteLineCount${rowNum}`].length - 2
                  ] -
                    formData[`minuteLineCount${rowNum}`][
                      formData[`minuteLineCount${rowNum}`].length - 3
                    ]
                )}${recordedString}`
              }
            }
          }
          if (
            activeInput.substring(0, 4) == "date" &&
            !this.state.isRecognizing
          ) {
            // eslint-disable-next-line no-param-reassign

            let newStr =
              recordedString && recordedString.replace(/th|st|rd|nd|of/g, "")
            let myDate = new Date(newStr)
            const dayOfMonth = myDate.getDate()
            const month = myDate.getMonth()
            const year = myDate.getFullYear()

            function pad(n) {
              return n < 10 ? "0" + n : n
            }
            if (isNaN(dayOfMonth)) {
              Swal.fire({
                timer: 2000,
                icon: "error",
                text: "please provide proper value " + recordedString,
                showCancelButton: false,
                showConfirmButton: false,
              })
              recordedString = ""
              this.setState({ ...formData, recordAuto: 1 })
            } else {
              recordedString =
                pad(dayOfMonth) + "/" + pad(month + 1) + "/" + year
            }

            if (this.state.inputHistory[activeInput].length == 0) {
              recordedString = "\r\n" + recordedString
            } else {
              const { rowNum } = this.state
              let str = "\r\n"

              if (formData[`minuteLineCount${rowNum}`].length == 2) {
                recordedString = `${str.repeat(
                  formData[`minuteLineCount${rowNum}`][0]
                )}${recordedString}`
              } else if (formData[`minuteLineCount${rowNum}`].length > 2) {
                recordedString = `${str.repeat(
                  formData[`minuteLineCount${rowNum}`][
                    formData[`minuteLineCount${rowNum}`].length - 2
                  ] -
                    formData[`minuteLineCount${rowNum}`][
                      formData[`minuteLineCount${rowNum}`].length - 3
                    ]
                )}${recordedString}`
              }
            }
          }

          if (activeInput.includes("_date") && !this.state.isRecognizing) {
            // eslint-disable-next-line no-param-reassign

            let newStr =
              recordedString && recordedString.replace(/th|st|rd|nd|of/g, "")
            let myDate = new Date(newStr)
            const dayOfMonth = myDate.getDate()
            const month = myDate.getMonth()
            const year = myDate.getFullYear()

            function pad(n) {
              return n < 10 ? "0" + n : n
            }
            if (isNaN(dayOfMonth)) {
              Swal.fire({
                timer: 2000,
                icon: "error",
                text: "please provide proper value " + recordedString,
                showCancelButton: false,
                showConfirmButton: false,
              })
              recordedString = ""
              this.setState({ ...formData, recordAuto: 1 })
            } else {
              recordedString =
                pad(dayOfMonth) + "/" + pad(month + 1) + "/" + year
            }
          }

          formData[activeInput] = `${(existingValue || "") + recordedString} `

          // this.setState((prevState) => ({
          //   formData: {
          //     ...formData,
          //     [activeInput]: `${(existingValue || "") + recordedString} `,
          //   },
          //   inputHistory: {
          //     ...prevState.inputHistory,
          //     [activeInput]: [
          //       ...prevState.inputHistory[activeInput],
          //       recordedString,
          //     ],
          //   },
          // }));

          this.setState((prevState) => ({
            ...formData,
            inputHistory: {
              ...prevState.inputHistory,
              [activeInput]: [
                ...prevState.inputHistory[activeInput],
                recordedString,
              ],
            },
          }))

          if (activeInput.includes("title")) {
            const srno = activeInput.charAt(activeInput.length - 1)
            this._findRefMinuteInput(
              minuteRefs,
              `minute${srno}.1.`
            ).current.focus()
            this.setState({ selectedProject: MINUTES, labelButton: true })
            this.setState({
              activeInput: `minute${srno}.1.`,
              recordAuto: 1,
            })
            // if(activeInput.include)
            // const index = this._goToNextInput(activeInput, minuteRefs);
            // minuteRefs[index].current.scrollIntoView({
            //   block: "center",
            //   behavior: "smooth",
            // });
          }
        }
        //  else {
        //   Swal.fire({
        //     timer: 2000,
        //     icon: "error",
        //     text: "please provide proper value " + recordedString,
        //     showCancelButton: false,
        //     showConfirmButton: false,
        //   });
        //   console.log("else", recordedString);
        // }
      }
    }
  }

  // eslint-disable-next-line react/sort-comp
  _goToNextInput(activeInput, refs) {
    const index = this._findIndexOfInput(refs, activeInput)
    return index
  }

  // Find referened input
  _findRefInput = (refArray, inputName) =>
    refArray.find((each) => each.current.name === inputName)

  _findIndexOfInput = (refArray, inputName) =>
    refArray.findIndex((each) => each.current.name === inputName)

  _focusOnInputStart = () => {
    this.setState({
      activeInput: this.state.projectRefs[0].current.name,
    })
    this.state.projectRefs[0].current.focus()
  }

  _findRefMinuteInput = (refArray, input) => {
    return refArray.find((each) => {
      if (each.current == null) {
        return
      } else {
        return each.current.name === input
      }
    })
  }

  // Start audio recording
  _startRecording = () => {
    if (this.state.activeEle) {
      this.state.activeEle.focus()
      this.setState({ activeEle: null })
    } else {
      this._focusOnInputStart()
    }

    recognizer.startContinuousRecognitionAsync()

    recognizer.sessionStarted = () => {
      this.setState({ isRecording: true })
      recognizer.recognizing = (s, e) => {
        let rawText
        this.setState({ recording: 1, isRecognizing: true })

        if (
          this.state.refjson &&
          this.state.refjson.commands.triggers.includes(e.result.text)
        ) {
          return
        } else if (
          this.state.selectedProject == ATTENDEES ||
          this.state.selectedProject == APOLOGIES
        ) {
          return
        } else if (this.state.activeInput.includes("date")) {
          return
        } else if (this.state.activeInput.includes("title")) {
          return
        } else {
          rawText = e.result.text
        }
        this.setState({ rawText: rawText })
        this._processCommand(rawText)
      }

      recognizer.recognized = (s, e) => {
        let recordedString
        this.setState({ recording: 1, isRecognizing: false })
        this.setState((prevState) => ({
          recognizeCount: prevState.recognizeCount + 1,
        }))
        if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
          recordedString = e.result.text
          this.setState({
            recordedString: recordedString,
            loading: true,
          })

          this._processCommand(recordedString)

          const {
            activeInput,
            dateRefs,
            minuteRefs,
            ownerRefs,
            statusRefs,
          } = this.state
          if (this.state.recordAuto === 0 && activeInput) {
            this.setState({ recordAuto: 1 })

            if (this.state.selectedProject === 1) {
              const index = this._goToNextInput(
                this.state.activeInput,
                this.state.projectRefs
              )
              if (this.state.projectRefs.length - 1 == index) {
                // this.setState({ recordAuto: 1 });
                this.setState({
                  selectedProject: ATTENDEES,
                  inAttendees: 1,
                  onAttendeesTable: 1,
                })
                this.attendeesRef.current.scrollIntoView()
              } else {
                this.setState({
                  activeInput: this.state.projectRefs[index + 1].current.name,
                })
                this.state.projectRefs[index + 1].current.focus()
              }
            } else if (this.state.selectedProject === 2) {
              const index = this._goToNextInput(
                this.state.activeInput,
                this.state.nextProjectRefs
              )
              if (this.state.nextProjectRefs.length - 1 == index) {
                this.setState({
                  activeInput: this.state.nextProjectRefs[0].current.name,
                })
                this.state.nextProjectRefs[0].current.focus()
              } else {
                this.setState({
                  activeInput: this.state.nextProjectRefs[index + 1].current
                    .name,
                })
                this.state.nextProjectRefs[index + 1].current.focus()
              }
            } else if (this.state.selectedProject == 4) {
              if (activeInput.includes("minute")) {
                const index = this._goToNextInput(activeInput, minuteRefs)
                {
                  if (this.state.owner_status === 1) {
                    this.setState({
                      activeInput: ownerRefs[index].current.name,
                    })
                    ownerRefs[index].current.focus()
                  } else if (this.state.date_status === 1) {
                    this.setState({
                      activeInput: dateRefs[index].current.name,
                    })
                    dateRefs[index].current.focus()
                  } else if (this.state.meeting_status === 1) {
                    this.setState({
                      activeInput: statusRefs[index].current.name,
                    })
                    statusRefs[index].current.focus()
                  } else if (minuteRefs.length - 1 === index) {
                    this.setState({
                      activeInput: this.state.nextProjectRefs[0].current.name,
                    })
                    this.state.nextProjectRefs[0].current.focus()
                  } else {
                    this.setState({
                      activeInput: minuteRefs[index + 1].current.name,
                    })
                    minuteRefs[index + 1].current.focus()
                  }
                }
              } else if (activeInput.includes("owner")) {
                const index = this._goToNextInput(activeInput, ownerRefs)
                {
                  if (this.state.date_status === 1) {
                    this.setState({
                      activeInput: dateRefs[index].current.name,
                    })
                    dateRefs[index].current.focus()
                  } else if (this.state.meeting_status === 1) {
                    this.setState({
                      activeInput: statusRefs[index].current.name,
                    })
                    statusRefs[index].current.focus()
                  } else if (minuteRefs.length - 1 === index) {
                    this.setState({
                      activeInput: this.state.nextProjectRefs[0].current.name,
                    })
                    this.state.nextProjectRefs[0].current.focus()
                  } else {
                    this.setState({
                      activeInput: minuteRefs[index + 1].current.name,
                    })
                    minuteRefs[index + 1].current.focus()
                  }
                }
              } else if (activeInput.includes("date")) {
                const index = this._goToNextInput(activeInput, dateRefs)
                if (this.state.meeting_status === 1) {
                  this.setState({
                    activeInput: statusRefs[index].current.name,
                  })
                  statusRefs[index].current.focus()
                } else if (minuteRefs.length - 1 === index) {
                  this.setState({
                    activeInput: this.state.nextProjectRefs[0].current.name,
                  })
                  this.state.nextProjectRefs[0].current.focus()
                } else {
                  this.setState({
                    activeInput: minuteRefs[index + 1].current.name,
                  })
                  minuteRefs[index + 1].current.focus()
                }
              } else if (activeInput.includes("status")) {
                const index = this._goToNextInput(activeInput, statusRefs)
                if (statusRefs.length - 1 == index) {
                  this.setState({
                    activeInput: this.state.nextProjectRefs[0].current.name,
                  })
                  this.state.nextProjectRefs[0].current.focus()
                } else {
                  this.setState({
                    activeInput: minuteRefs[index + 1].current.name,
                  })
                  minuteRefs[index + 1].current.focus()
                }
              }
            }
          }

          this.setState({ loading: false })
        } else {
          this.setState({ recordedString: null, loading: true })
        }
      }
    }

    // Handle user declined audio permissions error
    recognizer.canceled = (s, e) => {
      console.log(`CANCELED: Reason=${e.reason}`)

      if (e.reason == speechsdk.CancellationReason.Error) {
        console.log(`"CANCELED: ErrorCode=${e.errorCode}`)
        console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`)
        this.setState({
          error: e.errorDetails,
        })
      }

      recognizer.stopContinuousRecognitionAsync()
    }
  }

  // stop recording
  _stopRecording = () => {
    recognizer.stopContinuousRecognitionAsync()
    recognizer.close()
    this.setState({
      activeInput: null,
      isRecording: false,
      forceStopped: true,
      loading: false,
    })
    Swal.fire({
      timer: 2000,
      icon: "info",
      text: "Recording Stop Successfully",
      showCancelButton: false,
      showConfirmButton: false,
    })
  }

  // Function to convert an image URL to base64
  // convertImgToBase64(url) {
  //   var img = new Image()
  //   img.crossOrigin = "Anonymous" // Enable cross-origin requests if needed
  //   let base64
  //   img.onload = function() {
  //     var canvas = document.createElement("canvas")
  //     canvas.width = img.width
  //     canvas.height = img.height

  //     var ctx = canvas.getContext("2d")
  //     ctx.drawImage(img, 0, 0)

  //     var dataURL = canvas.toDataURL("image/png")
  //     base64 = dataURL.split(",")[1] // Extract the base64 part
  //   }

  //   img.src = url
  //   return img
  // }

  // pause recording
  _pauseRecording = async () => {
    window.scrollTo(0, 0)

    // Clear any existing timer
    clearTimeout(pauseTimer)

    // Start a new timer for auto-saving after 30 seconds
    pauseTimer = setTimeout(async () => {
      await this.updateFormData()
    }, 30000)

    await recognizer.stopContinuousRecognitionAsync()
    recognizer.sessionStopped = async () => {
      let activeEle = document.activeElement
      this.setState({
        isRecording: false,
        // forceStopped: true,
        loading: false,
      })
      activeEle.blur()
      this.setState({
        activeEle: activeEle,
      })
      // updating data in api when pausing

      if (!this.state.forceStopped)
        Swal.fire({
          timer: 2000,
          icon: "info",
          text: "Recording Paused!",
          showCancelButton: false,
          showConfirmButton: false,
        })
      await this.loadData()
    }
  }

  // setting final line count after updating minute fields multiple times
  _setLineCount = () => {
    const { formData, minuteLineCount } = this.state

    this.state.refjson &&
      this.state.refjson.minutesFields.forEach((each) => {
        each.minutes.forEach((subEach) => {
          if (
            minuteLineCount[subEach.minuteLineCount].length <
            formData[subEach.minuteLineCount].length
          ) {
            let startingIndex = minuteLineCount[subEach.minuteLineCount].length
            let numberOfIndexToBeRemoved =
              formData[subEach.minuteLineCount].length -
              minuteLineCount[subEach.minuteLineCount].length -
              1
            formData[subEach.minuteLineCount].splice(
              startingIndex,
              numberOfIndexToBeRemoved
            )

            this.setState((prevState) => ({
              formData: {
                ...formData,
              },
            }))
          }
        })
      })
  }

  // Handle submit button
  _handleSubmit = async (e) => {
    this.hideModalSubmit()
    this._setLineCount()
    this.setState({ submitted: true })
    const { meeting_data } = this.state

    window.scrollTo(0, 0)
    this.setState({
      activeInput: null,
      isRecording: false,
      forceStopped: true,
      loading: false,
      // isLoading: true,
    })

    await recognizer.stopContinuousRecognitionAsync()
    await recognizer.close()
    await this.updateFormData()

    Swal.fire({
      position: "center",
      icon: "success",
      title: "Your work has been saved",
      showConfirmButton: false,
      timer: 1500,
    })

    $("textarea").replaceWith(function() {
      return (
        `<pre style="white-space: pre-wrap; font-family: ${meeting_data?.font_family};">` +
        $(this).html() +
        "</pre>"
      )
    })

    await new Promise((resolve) => setTimeout(resolve, 1000))
    var content = document.getElementById("whole-page")

    html2canvas(content).then(async (contentCanvas) => {
      // Calculate total height including canvas
      const totalHeight = contentCanvas.height
      let pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [contentCanvas.width, totalHeight],
        compressPdf: true,
      })

      pdf.addImage(
        contentCanvas.toDataURL("image/png"),
        "PNG",
        15,
        15,
        contentCanvas.width + 15,
        contentCanvas.height + 15
      )

      pdf.save(`${meeting_id.meeting_id}.pdf`)

      var blob = pdf.output("blob")
      var formData = new FormData()
      formData.append("meeting_pdf", blob)
      formData.append("meeting_id", meeting_id.meeting_id)
      axios
        .post(
          `${process.env.REACT_APP_API_URI}/api/update-meeting-pdf`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.loadData()
          window.location.reload()
        })
        .catch((err) => console.log(err))
    })
  }

  // Handle input change
  _handleChange = (e) => {
    const { formData, selectedProject } = this.state

    this.setState({
      formData: { ...formData, [e.target.name]: e.target.value },
    })
  }

  // Debug mode change
  _onDebugModeChange = () => {
    const { debugMode } = this.state
    this.setState({ debugMode: !debugMode })
  }

  // set currently active input and projects
  _setActiveProjectInput = (activeInput) => {
    this.setState({ activeInput, selectedProject: PROJECT })
  }

  _setActiveNextProjectInput = (activeInput) =>
    this.setState({ activeInput, selectedProject: NEXT_MEETING })

  _setActiveMinutesProjectInput = (activeInput) =>
    this.setState({ activeInput, selectedProject: MINUTES })

  // toggle modals - help modal, minutes help modal
  _hideModal = () => this.setState({ showModal: false })

  showModalSubmit = () => this.setState({ showModalSubmit: true })

  // Function to hide the submit modal
  hideModalSubmit = () => this.setState({ showModalSubmit: false })

  _onHelpModal = () => this.setState({ helpModal: false })
  _onHelpModalMinutes = () => this.setState({ helpModalMinutes: true })
  _onHelpModalNextProject = () => this.setState({ helpModalNextProject: true })

  _onMinutesHelpModal = () => this.setState({ minutesModal: true })

  openModal = () => this.setState({ showModalProject: true })
  closeModal = () => this.setState({ showModalProject: false })

  _onHelpModalAttendees = () => this.setState({ helpModalAttendees: true })
  _onHelpHideModalAttendees = () => this.setState({ helpModalAttendees: false })

  _onHelpModalApologies = () => this.setState({ helpModalApologies: true })
  _onHelpHideModalApologies = () => this.setState({ helpModalApologies: false })

  _onHelpHideModal = () => this.setState({ helpModal: false })
  _onHelpHideModalMinutes = () => this.setState({ helpModalMinutes: false })
  _onHelpHideModalNextProject = () =>
    this.setState({ helpModalNextProject: false })
  _onMinutesHelpHideModal = () => this.setState({ minutesModal: false })

  // converting label button to editable
  _onChangeLabelButton = () => this.setState({ labelButton: false })

  // setting line counts
  _lineCount = (lines) => this.setState({ lines })

  // setting height of minute fields to store it in API and regain it while updating the minute
  _handleHeightMinute = (heightOfMinute) => this.setState({ heightOfMinute })

  // adding minute line count in array so that we can align owner name and date to new updates
  _minuteLineCount = (minuteLineCnt, minuteLineHeight) => {
    const { lines, formData, heightOfMinute } = this.state
    this.setState({
      bulletPoints: false,
      nestedPoint: false,
      sequencePoints: false,
      nestedSeq: false,
      sequenceNumber: 0,
    })

    this.setState((prevState) => ({
      formData: {
        ...formData,
        [minuteLineCnt]:
          formData[minuteLineCnt][formData[minuteLineCnt].length - 1] !==
            lines || formData[minuteLineCnt].length == 0
            ? [...formData[minuteLineCnt], lines]
            : [...formData[minuteLineCnt]],
        [minuteLineHeight]: heightOfMinute,
      },
    }))
  }

  _updateRefJSONFile = async (json) => {
    for (let i = 0; i < json.minutesFields.length; i++) {
      json.minutesFields[i].label = labels[i]
      json.minutesFields[i].no = nos[i]
    }
    let newJson = JSON.parse(JSON.stringify(json))
    let activeEle = document.activeElement
    this.setState({ isLoading: true, activeEle })

    let payload = {
      json: newJson,
      meeting_id: meeting_id.meeting_id,
      type: type.type,
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/update-meeting-json-file`,
        payload
      )
    } catch (error) {
      console.log(error)
    }

    this.setState({ isLoading: false })
    this.state.activeEle.focus()
    this.setState({ activeEle: null })
    await this.loadData()
  }

  render() {
    const {
      isRecording,
      refs,
      projectRefs,
      nextProjectRefs,
      loading,
      formData,
      showModal,
      showModalSubmit,
      helpModal,
      minutesModal,
      debugMode,
      recordedString,
      error,
      minuteRefs,
      ownerRefs,
      statusRefs,
      dateRefs,
      meeting_data,
      isLoading,
      highlightedTables,
    } = this.state

    // const {
    //   position_left: orgX,
    //   position_top: orgY,
    //   width: orgWidth,
    //   height: orgHeight,
    // } = orgCoords
    // const {
    //   position_left: textX,
    //   position_top: textY,
    //   width: textWidth,
    //   height: textHeight,
    // } = textCoords
    // const {
    //   position_left: prodX,
    //   position_top: prodY,
    //   width: prodWidth,
    //   height: prodHeight,
    // } = prodCoords

    if (isLoading) return <LoadingSpinner />
    return (
      <Row id="whole-page">
        {/* first column */}
        <Col
          span={
            this.state.showModalProject ||
            this.state.helpModalApologies ||
            this.state.helpModalAttendees ||
            this.state.helpModalMinutes ||
            this.state.helpModalNextProject
              ? 18
              : 24
          }
          style={{
            position:
              this.state.showModalProject ||
              this.state.helpModalApologies ||
              this.state.helpModalAttendees ||
              this.state.helpModalMinutes ||
              this.state.helpModalNextProject
                ? "relative"
                : "static", // Set to 'static' when not in a modal state
            overflowY:
              this.state.showModalProject ||
              this.state.helpModalApologies ||
              this.state.helpModalAttendees ||
              this.state.helpModalMinutes ||
              this.state.helpModalNextProject
                ? "auto"
                : "visible", // Adjust overflow based on modal state
            maxHeight:
              this.state.showModalProject ||
              this.state.helpModalApologies ||
              this.state.helpModalAttendees ||
              this.state.helpModalMinutes ||
              this.state.helpModalNextProject
                ? "100vh"
                : "initial", // Adjust max height based on modal state
          }}
        >
          <Col span={1} />
          <Col span={22} className="content">
            {error && (
              <>
                <Alert type="error" message={error} showIcon />
                <br />
              </>
            )}
            {/* <Row height="300px">
              <Col span={24}>
                <div
                  className="container"
                  style={{ height: "300px !important" }}
                >
                  {meeting_organization_logo && (
                    <img
                      src={meeting_data.meeting_organization_logo}
                      alt="Organization Logo"
                      style={{
                        position: "relative",
                        // display: "inline-block",
                        top: `${orgCoords.position_top * 2}px`,
                        left: `${orgCoords.position_left}%`,
                        width: `${orgCoords.width}px`,
                        height: `${orgCoords.height}px`,
                      }}
                    />
                  )}

                  {meeting_data.textInput && (
                    <p
                      style={{
                        position: "relative",
                        // display: "inline-block",
                        top: `${textCoords.position_top * 2}px`,
                        left: `${textCoords.position_left}%`,
                        width: `${textCoords.width}px`,
                        height: `${textCoords.height}px`,
                      }}
                    >
                      {meeting_data.textInput}
                    </p>
                  )}

                  {meeting_data.meeting_product_logo && (
                    <img
                      src={meeting_data.meeting_product_logo}
                      alt="Product Logo"
                      style={{
                        position: "relative",
                        // display: "inline-block",
                        top: `${prodCoords.position_top * 2}px`,
                        left: `${prodCoords.position_left}%`,
                        width: `${prodCoords.width}px`,
                        height: `${prodCoords.height}px`,
                      }}
                    />
                  )}
                </div>
              </Col>
            </Row> */}

            <Row>
              <Col span={24}>
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    // border: "1px solid #ccc",
                    overflow: "hidden",
                  }}
                  className="container mt-2"
                  ref={this.canvasRef}
                >
                  <canvas id="canvas"></canvas>
                </div>

                <Header
                  meeting_data={meeting_data}
                  activeEle={this.state.activeEle}
                  helpModal={helpModal}
                  minutesModal={minutesModal}
                  onHelpModal={this._onHelpModal}
                  onHelpHideModal={this._onHelpHideModal}
                  onMinutesHelpModal={this._onMinutesHelpModal}
                  onMinutesHelpHideModal={this._onMinutesHelpHideModal}
                  _handleSubmit={this._handleSubmit}
                  closeModal={this.closeModal}
                  onHelpHideModalAttendees={this._onHelpHideModalAttendees}
                  onHelpHideModalApologies={this._onHelpHideModalApologies}
                  onHelpHideModalNextProject={this._onHelpHideModalNextProject}
                  onHelpHideModalMinutes={this._onHelpHideModalMinutes}
                  onChangeLanguage={(value) => {
                    this.setState({ lcValue: value })
                    // eslint-disable-next-line react/no-unused-state
                    // this.setState({ lcValue: value }, () => {
                    //   const selectedFields = getFieldsByLang(value);
                    // });
                  }}
                  onStartRecording={(value) => {
                    this._startRecording()
                  }}
                  isStopRecording={isRecording}
                  onStopRecording={(value) => {
                    this._stopRecording()
                  }}
                  showModalSubmit={this.showModalSubmit}
                />
              </Col>
            </Row>

            {/* <Row>
              <Col span={24}>
                <Row>
                  <Col span={8} />
                  <Col span={14} className="align-right">
                    {loading && (
                      <Spin
                        indicator={
                          <Icon type="loading" style={{ fontSize: 18 }} spin />
                        }
                      />
                    )}
                    {debugMode && recordedString && (
                      <span className="debug">{recordedString}</span>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row> */}
            <div className="container mt-2">
              <div
                ref={this.projectRef}
                className={
                  this.state.selectedProject == 1 ? "projects-wrapper" : ""
                }
              >
                <Project
                  fields={this.state.refjson && this.state.refjson.fields}
                  formData={formData}
                  refs={projectRefs}
                  labelButton={this.state.labelButton}
                  _handleChange={this._handleChange}
                  _setActiveProjectInput={this._setActiveProjectInput}
                  _handleSubmit={this._handleSubmit}
                  showModal={this.state.showModalProject}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  onHelpHideModalAttendees={this._onHelpHideModalAttendees}
                  onHelpHideModalApologies={this._onHelpHideModalApologies}
                  onHelpHideModalNextProject={this._onHelpHideModalNextProject}
                  onHelpHideModalMinutes={this._onHelpHideModalMinutes}
                />
              </div>
            </div>
            <div className="container mt-2">
              <div
                ref={this.attendeesRef}
                className={
                  this.state.selectedProject == 3 ||
                  this.state.selectedProject === 5
                    ? "projects-wrapper"
                    : ""
                }
              >
                <Attendees
                  selectedProject={this.state.selectedProject}
                  fields={this.state.attendeeFieldList}
                  apologiesList={this.state.apologiesList}
                  isAttendess={this.state.onAttendeesTable}
                  isApologies={this.state.onApologiesTable}
                  helpModalAttendees={this.state.helpModalAttendees}
                  onHelpModalAttendees={this._onHelpModalAttendees}
                  onHelpHideModalAttendees={this._onHelpHideModalAttendees}
                  helpModalApologies={this.state.helpModalApologies}
                  onHelpModalApologies={this._onHelpModalApologies}
                  onHelpHideModalApologies={this._onHelpHideModalApologies}
                  onHelpHideModalMinutes={this._onHelpHideModalMinutes}
                  closeModal={this.closeModal}
                  onHelpHideModalNextProject={this._onHelpHideModalNextProject}
                />
              </div>
            </div>
            <div className="container mt-2">
              <div
                ref={this.minutesRef}
                className={
                  this.state.selectedProject == 4 ? "projects-wrapper" : ""
                }
              >
                <Minutes
                  labelRef={this.state.labelRef}
                  _findRefMinuteInput={this._findRefMinuteInput}
                  fields={
                    this.state.refjson && this.state.refjson.minutesFields
                  }
                  rowNum={this.state.rowNum}
                  activeInput={this.state.activeInput}
                  formData={formData}
                  minuteRefs={minuteRefs}
                  ownerRefs={ownerRefs}
                  statusRefs={statusRefs}
                  date_status={this.state.date_status}
                  owner_status={this.state.owner_status}
                  meeting_status={this.state.meeting_status}
                  submitted={this.state.submitted}
                  dateRefs={dateRefs}
                  _handleChange={this._handleChange}
                  _onChangeLabelButton={this._onChangeLabelButton}
                  labelButton={this.state.labelButton}
                  labelRefs={this.state.labelRefs}
                  _setActiveMinutesProjectInput={
                    this._setActiveMinutesProjectInput
                  }
                  _minuteLineCount={(minuteLineCnt, minuteLineHeight) =>
                    this._minuteLineCount(minuteLineCnt, minuteLineHeight)
                  }
                  lines={this.state.lines}
                  _lineCount={this._lineCount}
                  _handleHeightMinute={this._handleHeightMinute}
                  helpModalMinutes={this.state.helpModalMinutes}
                  onHelpModalMinutes={this._onHelpModalMinutes}
                  onHelpHideModalMinutes={this._onHelpHideModalMinutes}
                  tableNumber={this.state.highlightedTables}
                  isHighlighted={highlightedTables.includes(this.tableNumber)}
                  onCheckboxChange={this.handleCheckboxChange}
                  closeModal={this.closeModal}
                  onHelpHideModalAttendees={this._onHelpHideModalAttendees}
                  onHelpHideModalApologies={this._onHelpHideModalApologies}
                  onHelpHideModalNextProject={this._onHelpHideModalNextProject}
                />
              </div>
            </div>
            <div className="container mt-2">
              <div
                ref={this.nextProjectRef}
                className={
                  this.state.selectedProject == 2 ? "projects-wrapper" : ""
                }
              >
                <NextProject
                  fields={
                    this.state.refjson && this.state.refjson.nextProjectFields
                  }
                  formData={formData}
                  refs={nextProjectRefs}
                  _handleChange={this._handleChange}
                  _setActiveNextProjectInput={this._setActiveNextProjectInput}
                  _handleSubmit={this._handleSubmit}
                  helpModalNextProject={this.state.helpModalNextProject}
                  onHelpModalNextProject={this._onHelpModalNextProject}
                  onHelpHideModalNextProject={this._onHelpHideModalNextProject}
                  closeModal={this.closeModal}
                  onHelpHideModalAttendees={this._onHelpHideModalAttendees}
                  onHelpHideModalApologies={this._onHelpHideModalApologies}
                  onHelpHideModalMinutes={this._onHelpHideModalMinutes}
                />
              </div>
            </div>
            <div className="container mt-2">
              {this.state.additionalDistributionList.length ? (
                <AdditionalDist
                  // additionalDist={meeting_data.additional_distribution}
                  additionalDistList={this.state.additionalDistributionList}
                />
              ) : null}
            </div>
            <Col span={30} className="align-right">
              <div className="container mt-2">
                <button
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    this.showModalSubmit()
                    this.closeModal()
                    this._onHelpHideModalAttendees()
                    this._onHelpHideModalApologies()
                    this._onHelpHideModalNextProject()
                    this._onHelpHideModalMinutes()
                    isRecording && this._stopRecording()
                  }}
                  data-html2canvas-ignore="true"
                >
                  Submit
                </button>
              </div>
              <Modal
                title=" Attach Files / Submit"
                visible={showModalSubmit}
                onCancel={this.hideModalSubmit}
                width={600}
                centered
                footer={[
                  <Button key="back" onClick={(val) => this.hideModalSubmit()}>
                    Close
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <p>Choose an option:</p>
                  <input
                    type="file"
                    onChange={this.handleFileChange}
                    multiple
                  />
                  or
                  <button
                    className="btn btn-success mx-3 px-1 px-lg-4 px-md-3"
                    onClick={(val) => this._handleSubmit()}
                  >
                    Submit
                  </button>
                </div>
              </Modal>
            </Col>
          </Col>
        </Col>
        {/* second column */}
        {this.state.showModalProject && <ProjectModal />}
        {this.state.helpModalApologies && <AttendeesModal />}
        {this.state.helpModalAttendees && <AttendeesModal />}
        {this.state.helpModalMinutes && <MinutesModal />}
        {this.state.helpModalNextProject && <NextProjectModal />}

        <div
          style={{
            bottom: "20px",
            right: "15px",
            position: "fixed",
            zIndex: "99",
          }}
        >
          <button
            style={{ border: "none", height: "50px" }}
            className="flex-end"
            onClick={() => window.scrollTo(0, 0)}
          >
            <i
              className="fa-solid fa-circle-arrow-up"
              style={{ fontSize: "200%" }}
              data-html2canvas-ignore="true"
            ></i>
          </button>
        </div>
      </Row>
    )
  }
}

export default hot(App)

{
  /* <div
                  className="container"
                  // style={{
                  //   position: "relative",
                  // }}
                > */
}
{
  /* <div
                    style={{
                      transform: `translate(${orgX}%, ${orgY}%)`,
                    }}
                  > */
}
{
  /* {meeting_data.meeting_organization_logo && (
                    <img
                      src={
                        meeting_data
                          ? meeting_data.meeting_organization_logo
                          : ""
                      }
                      style={{
                        // display: "inline-block",
                        position: "relative",
                        top: `${orgY}%`,
                        left: `${orgX}%`,
                        width: `${orgWidth}px`,
                        height: `${orgHeight}px`,
                      }}
                    />
                  )} */
}
{
  /* </div> */
}
{
  /* {meeting_data.textInput && (
                    <p
                      style={{
                        // transform: `translate(${textX}%, ${textY}%)`,
                        // display: "inline-block",
                        position: "relative",
                        top: `${textY}%`,
                        left: `${textX}%`,
                        width: `${textWidth}px`,
                        height: `${textHeight}px`,
                      }}
                    >
                      {meeting_data.textInput}
                    </p>
                  )} */
}
{
  /* <div>
                    <div
                      style={{
                        transform: `translate(${prodX}%, ${prodY}%)`,
                      }}
                    > */
}
{
  /* {meeting_data.meeting_product_logo && (
                    <img
                      src={
                        meeting_data ? meeting_data.meeting_product_logo : ""
                      }
                      style={{
                        position: "relative",
                        // display: "inline-block",
                        top: `${prodY}%`,
                        left: `${prodX}%`,
                        width: `${prodWidth}px`,
                        height: `${prodHeight}px`,
                      }}
                    />
                  )} */
}
{
  /* </div>
                  </div> */
}
{
  /* </div> */
}
