import "./Project.css";
import React from "react";
import PropTypes from "prop-types";

function Project({
  fields,
  formData,
  msg,
  refs,
  _handleChange,
  _setActiveInput,
  _setActiveProjectInput,
  showModal,
  openModal,
  closeModal,
  onHelpHideModalNextProject,
  onHelpHideModalMinutes,
  onHelpHideModalAttendees,
  onHelpHideModalApologies
}) {
  return (
    <div className="container  mt-2">
      <div className="row pb-3 pt-4 border1 rounded shadow">
        <div className="col-12">
          <h6 className="module-title rounded title-label-text-color border m-0 px-2 py-1 d-flex justify-content-between align-items-center">
            <span> 
            <i className="bi bi-mic-fill" data-html2canvas-ignore="true"></i> Project
            </span>
                  {showModal ? 
                  <span onClick={closeModal}>
                  <i className="bi bi-x" ></i>
                 </span>
                    :  
                    <span onClick={()=>{
                      openModal()
                      onHelpHideModalNextProject()
                      onHelpHideModalMinutes()
                      onHelpHideModalAttendees()
                      onHelpHideModalApologies()
                    }} data-html2canvas-ignore="true">
                    Help
                  </span>}
          </h6>
        </div>
        {fields.map((each, index) => {
          return (
            <div className="col-4 mt-3" key={each.name}>
              <h6 className="module-field rounded field-label-text-color m-0 px-2 py-1">
                <i data-html2canvas-ignore="true" className="bi bi-mic-fill"></i>
                {each.label}
              </h6>
              <input
                placeholder={each.placeholder}
                name={each.name}
                ref={refs[index]}
                onChange={_handleChange}
                onFocus={() => _setActiveProjectInput(each.name)}
                onBlur={() => _setActiveInput(null)}
                value={formData[each.name]}
                autoComplete="off"
                className="form-control mt-2"
              ></input>
            </div>
          )
        })}
          </div>
</div>

  )
}
Project.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  formData: PropTypes.shape({
    project_reference: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    meeting: PropTypes.string,
    meeting_reference: PropTypes.string,
    location: PropTypes.string,
  }),
  refs: PropTypes.arrayOf(PropTypes.object),
  _handleChange: PropTypes.func,
  _setActiveProjectInput: PropTypes.func,
  _handleSubmit: PropTypes.func,
}

Project.defaultProps = {
  fields: [],
  formData: [],
  refs: [],
  _handleChange: () => {},
  _setActiveInput: () => {},
  _handleSubmit: () => {},
}

export default Project
